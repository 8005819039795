import React from 'react';

import Sidebar from '../components/layout/Sidebar';
import CardVoluntary from '../components/customs/CardVoluntary';
import BottomNav from '../components/BottomNav';


class Voluntaries extends React.Component {
  constructor(props) {
    super(props);
    this.from = this.props.location.state?.from || { pathname: '/' };
    this.state = {
      volunteering: [
        {
          url: 'https://www.rosario.gob.ar/estilos/iframes/assets/img/logo.svg',
          title: 'Servir el almuerzo a los niños del comedor Sonrisas Felices',
          points: '280',
          distance: 3
        },
        {
          url: 'https://www.sociedadescomplejas.org.ar/eventos/LOMAS18/img/logo_lomas.png',
          title: 'Servir el almuerzo a los niños del comedor Sonrisas Felices',
          points: '280',
          distance: 3
        },
        {
          url: 'https://www.rosario.gob.ar/estilos/iframes/assets/img/logo.svg',
          title: 'Servir el almuerzo a los niños del comedor Sonrisas Felices',
          points: '280',
          distance: 3
        },
      ],
    }
  }
  
  componentDidMount() {
    console.log('Voluntarios');
  }

 
  render() {
    return (

      <main className="light-primary-bg h-full">
        <Sidebar 
          header={{ 
            title: "Voluntariados",
          }}
        />

        <section className="p-4">
          {
            this.state.volunteering.map((v, index) => {
              return (
                <CardVoluntary
                  className="mb-3"
                  key={'img' + index}
                  url={v.url}
                  title={v.title}
                  points={v.points}
                  distance={v.distance}
                />
              )
            })
          }
        </section>
        <BottomNav/>
      </main>
    ) 
  }
}

export default Voluntaries;
