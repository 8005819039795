import React from 'react';

import Sidebar from '../components/layout/Sidebar';
import CardBenefit from '../components/customs/CardBenefit';
import BottomNav from '../components/BottomNav';


class Benefits extends React.Component {
  constructor(props) {
    super(props);
    this.from = this.props.location.state?.from || { pathname: '/' };
    this.state = {
      benefits: [
        {
          url: 'https://tentulogo.com/wp-content/uploads/2017/07/mcdonalds-logo.jpg',
          title: '1 combo bigmac',
          points: 120,
        },
        {
          url: 'https://static.nike.com/a/images/f_jpg,q_auto:eco/61b4738b-e1e1-4786-8f6c-26aa0008e80b/swoosh-logo-black.png',
          title: '30% en todas las tiendas',
          points: 90,
        },
        {
          url: 'https://tentulogo.com/wp-content/uploads/2017/07/mcdonalds-logo.jpg',
          title: '2x1 en Papas',
          points: 120,
        },
      ],
    }
  }
  
  componentDidMount() {
    console.log('Benefits');
  }

 
  render() {
    return (

      <main className="light-primary-bg h-full">
        <Sidebar 
          header={{ 
            title: "Beneficios",
          }}
        />

        <section className="p-4">
          <div className="grid grid-cols-2 gap-4">
          {
            this.state.benefits.map((b, index) => {
              return (
                <CardBenefit 
                  key={'img' + index}
                  url={b.url}
                  title={b.title}
                  points={b.points}
                />
              )
            })
          }
          </div>
        </section>
        <BottomNav/>
      </main>
    ) 
  }
}

export default Benefits;
