import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form } from 'react-final-form'
import setFieldData from 'final-form-set-field-data';

import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
// import { composeValidators, validateEmail, validateIsfilled, getObjectWithJsonDataToFromValues } from '../../libraries/utils';

import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';
import SwitchboxInput from '../../components/forms/SwitchboxInput';
import UserProfileImage from '../../components/commons/UserProfileImage';
import UserPoints from '../../components/customs/UserPoints';
import MenuItemAdvance from '../../components/commons/MenuItemAdvance';
import LanguageSwitcher from '../../components/commons/LenguageSwitcher';

class UserSettings extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      settings: { 
        notification: 0,
      }
    }
    this.mainMenu = [
      { icon: { left: 'user_info', right: 'arrow_right' }, label: { text: this.t('Personal information') },
        action: { route: config.ROUTES.USERINFO }
      },
      { icon: { left: 'notification' }, label: { text: this.t('Notifications') },
        action: { 
          field: {
            // component: <Field name="notification" component={SwitchboxInput} placeholder="notification" className="h-12 pt-3" />,
            component: SwitchboxInput,
            name: 'notification',
            placeholder: this.t('Notifications'),
            className: 'h-12 pt-3'
          }
        }
      },
      { icon: { left: 'language' }, label: { text: this.t('Language') },
        action: { 
          field: {
            component: LanguageSwitcher,
            name: 'language',
            placeholder: this.t('Language'),
            className: 'h-12 pt-3'
            // html: <span>{this.t("Spanish")}</span>
          }
        }
      },
      { icon: { left: 'terms', right: 'arrow_right' }, label: { text: this.t('Terms and conditions') },
        action: { route: config.ROUTES.TERMS } },
      { icon: { left: 'helpme', right: 'arrow_right' }, label: { text: this.t('Help') },
        action: { route: config.ROUTES.HELP } },
      { icon: { left: 'user_info', right: 'arrow_right' }, label: { text: this.t('Change password') },
        action: { route: config.ROUTES.PASSWORD_CHANGE }
      },
      { icon: { left: 'logout' }, label: { text: this.t('Logout'), className: 'text-red-700' },
        action: { fn: () => this.props.onLogout() }
      },
    ];

  }

  componentDidMount() {
    console.log('PROFILE PAGE', this.props.auth.user);
    this.getUser();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.settings === this.state.settings) {
      return false;
    }
    return true;
  }

  getUser = async () => {
    console.log('GETTING USER');
    await this.props.onGetUser({ id: this.props.auth.user.id });
    console.log('AFTER GET');
    const error = this.props.user.error;
    if (error) {
      notify(this.t(error.message));
      await this.props.onLogout();
      history.push(config.ROUTES.LOGIN);
    } else {
      this.setState({ settings: { ...this.props.user.item.accessibility } });
    }
  }

  onSubmit = (field, value) => {
    console.log('ONSUBMIT', field, value);
    let data = { id: this.props.auth.user.id, accessibility: {} };
    data.accessibility[field] = value;
    console.log('DATA', data);
    this.props.onUpdate(data).then(()=> {
      console.log('AFTER UPDATE');
      const error = this.props.user.error;
      if (error) notify(this.t(error.message));
    });
  }

  render() {
    const user = this.props.auth.user;
    const { settings } = this.state;
    const points = user.points || 0;
    return (
      <LayoutSmall
        main={{ className: "light-primary-bg text-primary-100"}}
        header={{ 
          className: "bg-primary text-white",
          left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.PROFILE) }
        }}
        container={{ className: "px-0"}}
        loading={this.props.user.loading}
        footer={{ content: (
          <div className="flex items-center text-center pb-2 text-gray-400 text-xs bg-transparent">
            <span className="m-auto">{this.t("Version")} {config.VERSION}</span>
          </div>          
        )}}
      >
        <ToastContainer/>
        <section className="bg-primary text-center px-3 pt-2 pb-10">
          <div className="mr-auto">
            <UserProfileImage data={user} picture={{ onClick: () => history.push(config.ROUTES.USERINFO) }}/>
            {/* <UserPoints icon="gout" points={`${points/config.POINTS.factor} ${this.t(config.POINTS.unit)}`} leyend={this.t("Points saved")}/> */}
          </div>
        </section>
        <section className="px-4 -mt-5">
          <Form
            initialValues={settings || {}}
            onSubmit={this.onSubmit}
            mutators={{ setFieldData }}
          >
            {({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto">
                <div className="card white">
                  { this.mainMenu.map((item, index) => 
                    <MenuItemAdvance key={index} item={item} onSubmit={this.onSubmit}/>
                  )}
                </div>
              </form>
              )}
          </Form>
        </section>
      </LayoutSmall>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    user: state.users.current,
    auth: state.users.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdate: (params) => dispatch(userActions.saveOrUpdate(params)),
    onGetUser: (params) => dispatch(userActions.get(params)),
    onLogout: () => dispatch(userActions.logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(UserSettings));
