export default {
  CLEAR_CURRENT: 'BOOKINGS_CLEAR_CURRENT',
  GET_REQUEST: 'BOOKINGS_GET_REQUEST',
  GET_SUCCESS: 'BOOKINGS_GET_SUCCESS',
  GET_FAILURE: 'BOOKINGS_GET_FAILURE',

  SAVE_REQUEST: 'BOOKINGS_SAVER_REQUEST',
  SAVE_SUCCESS: 'BOOKINGS_SAVE_SUCCESS',
  SAVE_FAILURE: 'BOOKINGS_SAVE_FAILURE',

  GETALL_REQUEST: 'BOOKINGS_GETALL_REQUEST',
  GETALL_SUCCESS: 'BOOKINGS_GETALL_SUCCESS',
  GETALL_FAILURE: 'BOOKINGS_GETALL_FAILURE',

  DELETE_REQUEST: 'BOOKINGS_DELETE_REQUEST',
  DELETE_SUCCESS: 'BOOKINGS_DELETE_SUCCESS',
  DELETE_FAILURE: 'BOOKINGS_DELETE_FAILURE',    

  UPDATE_REQUEST: 'BOOKINGS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'BOOKINGS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'BOOKINGS_UPDATE_FAILURE',

  AVAILABILITY_REQUEST: 'BOOKINGS_AVAILABILITY_GET_REQUEST',
  AVAILABILITY_SUCCESS: 'BOOKINGS_AVAILABILITY_GET_SUCCESS',
  AVAILABILITY_FAILURE: 'BOOKINGS_AVAILABILITY_GET_FAILURE',

};