import React from 'react';

import LayoutSmall from '../components/layout/LayoutSmall';
import { history } from '../routes';
import config from '../config';


class GreenPage extends React.Component {
  constructor(props) {
    super(props);
    this.from = this.props.location.state?.from || { pathname: '/' };
    this.state = {      
    }
  }
  
  componentDidMount() {
    console.log('BenefitPage');
  }

 
  render() {
    return (
      <LayoutSmall
        main={{ className: "light-primary-bg text-primary w-full h-full"}}
        header={{ 
          className: "bg-white text-primary flex justify-between p-3",
          left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.BENEFITS) }
        }}
        container={{ className: "px-0"}}
      > 
        <div className="bg-white shadow-lg">
          <div className="container p-4">
            <div>
              <img src="https://thumbs.dreamstime.com/b/diversas-donaciones-en-una-caja-ropa-efectos-de-escritorio-y-juguetes-para-los-ni%C3%B1os-adolescentes-preparaci-n-la-escuela-aislado-153096922.jpg" alt="" width="100%" />
            </div>
            
            <h1 className="h1 text-secondary mb-5 mt-2">Doná alimentos no perecederos y ganá puntos ayudando.</h1>
            <div className="flex align-center justify-between">
              <p className="points big text-secondary">240 pts </p>
              <div>
                <small className="text-gray-500">3km</small>
              </div>
            </div>
            <button className="btn btn-block btn-primary shadow py-2 px-4 rounded mt-5" type="button">
              Donar
            </button>
          </div>
        </div>

        <section className="container-fluid p-4">
          <h4 className="text-secondary mb-4">Descripción</h4>
          <p className="text-gray-600">El día Domingo 27 de Septiembre se requiere la ayuda de 20 personas para ayudarnos a servir los platos con comida a chicos de 6 a 12 años que almuerzan de forma recurrente todos los días en el Comedor "Sonrisas Felices".

            Dirección: Av Dardo Rocha 2023, Rosario.
            Fecha: 27 de Septiembre
            Horario: 12 a 14 pm
            Requisito: Llevar barbijo propio. 
            (nosotros les daremos los guantes de latex)</p>
        </section>

      </LayoutSmall>
    ) 
  }
}

export default GreenPage;
