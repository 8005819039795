import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createBrowserHistory } from 'history';

import { PrivateRoute } from '../components/commons/PrivateRoute';
import config from '../config';

import Home from '../pages/Home';
import Error from '../pages/commons/Error';

import UserLogin from '../pages/auth/UserLogin';
import UserSignup from '../pages/auth/UserSignup';
import UserFullSignup from '../pages/auth/UserFullSignup';
import UserPassword from '../pages/auth/UserPassword';
import UserPasswordChange from '../pages/auth/UserPasswordChange';
import UserVerify from '../pages/auth/UserVerify';
import UserInfo from '../pages/auth/UserInfo';
import UserSettings from '../pages/auth/UserSettings';
import UserForgot from '../pages/auth/UserForgot';
import UserProfile from '../pages/auth/UserProfile';

// import Success from 'pages/Success';
import Inventory from '../pages/Inventory';
import Loans from '../pages/Loans';
import Returns from '../pages/Returns';
import Favourites from '../pages/Favourites';
import SearchList from '../pages/SearchList';
import Voluntaries from '../pages/Voluntaries';
import Activities from '../pages/Activities';
import VoluntaryPage from '../pages/VoluntaryPage';
import Benefits from '../pages/Benefits';
import BenefitPage from '../pages/BenefitPage';
import GreenPage from '../pages/GreenPage';
import ProductPage from '../pages/ProductPage';
import QrPage from '../pages/QrPage';
import SearchPage from '../pages/SearchPage';
import CategoryPage from '../pages/CategoryPage';

import InfoTerms from '../pages/info/InfoTerms';
import InfoPrivacy from '../pages/info/InfoPrivacy';
import InfoContactUs from '../pages/info/InfoContactUs';
import InfoHowWorks from '../pages/info/InfoHowWorks';
import InfoHelp from '../pages/info/InfoHelp';


export const history = createBrowserHistory();


export const Routes = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  return (
    <Switch>
      <Route path={config.ROUTES.TERMS} exact component={InfoTerms} />
      <Route path={config.ROUTES.PRIVACY} exact component={InfoPrivacy} />
      <Route path={config.ROUTES.CONTACT_US} exact component={InfoContactUs} />
      <Route path={config.ROUTES.HOW_WORKS} exact component={InfoHowWorks} />
      <Route path={config.ROUTES.HELP} exact component={InfoHelp} />

      <Route path={config.ROUTES.LOGIN} exact component={UserLogin} />
      <Route path={config.ROUTES.SIGNUP} exact component={UserSignup} />
      <Route path={config.ROUTES.SIGNUP_FULL} exact component={UserFullSignup} />
      <Route path={config.ROUTES.PASSWORD} exact component={UserPassword} />
      <Route path={config.ROUTES.VERIFY} exact component={UserVerify} />
      <Route path={config.ROUTES.FORGOT} exact component={UserForgot} />      
      <PrivateRoute path={config.ROUTES.PASSWORD_CHANGE} exact component={UserPasswordChange} />
      <PrivateRoute path={config.ROUTES.PROFILE} exact component={UserProfile} />
      <PrivateRoute path={config.ROUTES.USERINFO} exact component={UserInfo} />      
      <PrivateRoute path={config.ROUTES.USERSETTINGS} exact component={UserSettings} />      


      {/* <PrivateRoute path={config.ROUTES.SUCCESS} exact component={Success} /> */}
      <PrivateRoute path={config.ROUTES.INVENTORY} exact component={Inventory} />
      <PrivateRoute path={config.ROUTES.LOANS} exact component={Loans} />
      <PrivateRoute path={config.ROUTES.RETURNS} exact component={Returns} />
      <PrivateRoute path={config.ROUTES.FAVOURITES} exact component={Favourites} />
      <PrivateRoute path={config.ROUTES.SEARCH_LIST} exact component={SearchList} />
      <PrivateRoute path={config.ROUTES.VOLUNTARIES} exact component={Voluntaries} />
      <PrivateRoute path={config.ROUTES.ACTIVITIES} exact component={Activities} />
      <PrivateRoute path={config.ROUTES.VOLUNTARY_PAGE} exact component={VoluntaryPage} />
      <PrivateRoute path={config.ROUTES.BENEFITS} exact component={Benefits} />
      <PrivateRoute path={config.ROUTES.BENEFIT_PAGE} exact component={BenefitPage} />
      <PrivateRoute path={config.ROUTES.GREEN_PAGE} exact component={GreenPage} />
      <PrivateRoute path={config.ROUTES.PRODUCT_PAGE} exact component={ProductPage} />
      <PrivateRoute path={config.ROUTES.QR_PAGE} exact component={QrPage} />
      <PrivateRoute path={config.ROUTES.SEARCH_PAGE} exact component={SearchPage} />
      <PrivateRoute path={config.ROUTES.CATEGORY_PAGE} exact component={CategoryPage} />

      <PrivateRoute path={config.ROUTES.HOME} exact component={Home} />
      <Route>
      <Error title={t('404')} headline={t('Page not found!')} button={t('Go back')}/>
      </Route>        
    </Switch>
  );
}