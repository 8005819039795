import React from 'react';
import { withTranslation } from 'react-i18next';
import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';


class InfoHowWorks extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
    }
  }
  
  componentDidMount() {
    console.log('HOW WORKS PAGE');
  }

  onExit = async values => {
    history.push(config.ROUTES.HOME);        
  }
 
  render() {
    return (
      <LayoutSmall
        header={{ 
          className: "bg-primary",
          title: this.t("How works?"),
          left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.HOME) }
        }}      
        main={{ 
          className: "light-primary-bg text-gray-700",
        }}
      >
        <p className="mt-3 mb-1">
        ELEX es una empresa de lavado de vehículos a domicilio ecológico 
        ( podemos realizar el servicio en cualquier locación, ya sea vía publica, estacionamiento privado, etc) 
        utilizamos productos biodegradables de alta tecnología en base a ceras naturales y cítricos que le da un acabado al lavado de encerado, 
        mejor que como si hubiese sido lavado de manera tradicional con agua. El lavado lo realizamos con microfibras especiales para proteger 
        la pintura de cualquier tipo de rayones.
        </p>
      </LayoutSmall>
    ) 
  }
}

export default withTranslation()(InfoHowWorks);
