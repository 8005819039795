import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form'

import userActions from '../../context/users/actions';
import filesActions from '../../context/files/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import { composeValidators, validateEmail, validateIsfilled, getObjectWithJsonDataToFromValues } from '../../libraries/utils';

import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import TextInput from '../../components/forms/TextInput';

class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      submitting: false,
      selectedFile: null,
      profile_image: null,
      user: null,
    }
  }
  
  componentDidMount() {
    console.log('USER INFO PAGE', this.props.auth.user);
    this.getUser();    
  }

  getUser = async () => {
    console.log('GETTING USER');
    await this.props.onGetUser({ id: this.props.auth.user.id });
    console.log('AFTER GET');
    const error = this.props.user.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      const user = getObjectWithJsonDataToFromValues(this.props.user.item, ['id', 'first_name', 'last_name', 'phone', 'email', 'profile_image']);
      this.setState({ user });
    }
  }

  gotoBack = async () => {
    await this.props.onGetToken();
    if (this.props.auth.error) notify(this.t(this.props.auth.error.message));
    history.push(config.ROUTES.PROFILE);
  }

  onSubmit = async values => {
    console.log('ON SUBMIT', values);
    this.setState({ submitting: true });
    await this.props.onUpdate(values);
    console.log('AFTER UPDATE');
    const error = this.props.user.error;
    if (error) {
      this.setState({ submitting: false });
      notify(this.t(error.message));
    } else {
      this.gotoBack();
    }
  }

  onFileChange = async event => {
    this.setState({ submitting: true });
    console.log('ON FILE CHANGE');
    this.setState({ selectedFile: event.target.files[0] });
    await this.onFileUpload(event.target.files[0]);
    console.log('ON FILE CHANGE FINISH');
    this.setState({ submitting: false });
  }

  onFileUpload = async (file) => {
    console.log('FILE', file.name, file.type);
    const formData = new FormData();
    formData.append(
      "myFile",
      file,
      file.name
    )
    await this.props.onPictureUpload(formData);
    console.log('FILES', this.props.files);
    const error = this.props.files.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      this.setState({ profile_image: this.props.files.files.fileInfo.location });
      this.props.onUpdate({ id: this.state.user.id, profile_image: this.props.files.files.fileInfo.location }).then(()=> {
        console.log('AFTER SAVE PICTURE');
        const error = this.props.user.error;
        if (error) notify(this.t(error.message));
      });       
    }
  }

  render() {
    const { user } = this.state;
    if (this.state.profile_image) {
      console.log('AVATAR UPLOADED');
      user.profile_image = this.state.profile_image;
    }
    const required = value => (validateIsfilled(value) ? undefined : this.t("This field is required"))
    const email = value => (validateEmail(value) ? undefined : this.t("Not a valid email"))

    return (
      <LayoutSmall
        main={{ className: ""}}
        header={{ 
          className: "",
          left: { icon: 'arrow_left', action: () => this.gotoBack() }
        }}
        loading={this.props.user.loading}
        container={{ className: "px-0"}}
      >
        <ToastContainer/>
        <section className="bg-primary text-center px-3 pt-2 pb-8"> 
          <div className="center mx-auto">
            <div className="px-4 py-3 rounded-lg text-center">
              <div className="rounded-full bg-gray-500 overflow-hidden flex items-center text-center m-auto w-28 h-28">
                {user && user.profile_image ? (
                  <img className="w-full h-auto" src={user && user.profile_image} alt="" />
                ) : (
                  <span className="m-auto text-white text-2xl">{user && user.name}</span>
                )}
              </div>
              <label className="cursor-pointer mt-6">
                <span className="btn btn-secondary btn-sm mt-3">{this.t("Upload a photo")}</span>
                <input type='file' className="hidden" multiple="multiple" accept="accept" onChange={this.onFileChange} />
              </label>
            </div>            
          </div>
        </section>
        <section className="px-4 -mt-5">
          <Form 
            initialValues={user || {}}
            onSubmit={this.onSubmit}
          >
            {({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto">
                <div className="card white py-3">
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field name="first_name" component={TextInput} placeholder={this.t("First name")} label={this.t("First name")}
                      validate={required}
                      className="text-gray-700"
                    />
                  </div>
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field name="last_name" component={TextInput} placeholder={this.t("Last name")} label={this.t("Last name")}
                      validate={required}
                      className="text-gray-700"
                    />
                  </div>
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field name="email" component={TextInput} placeholder={this.t("Email")} label={this.t("Email")}
                      validate={composeValidators(required, email)}
                      className="text-gray-700"
                    />
                  </div>
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field name="phone" component={TextInput} placeholder={this.t("Phone number")} label={this.t("Phone number")}
                      validate={required}
                      className="text-gray-700"
                    />
                  </div>
                  <div className="w-full px-3">
                    <Button
                      className="btn btn-block btn-secondary"
                      title={this.t("Save")}
                      onClick={handleSubmit}
                      disabled={this.state.submitting || pristine}
                    />
                  </div>
                </div>
              </form>
            )}
          </Form>
        </section>
      </LayoutSmall>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    files: state.files,
    user: state.users.current,
    auth: state.users.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdate: (params) => dispatch(userActions.saveOrUpdate(params)),
    onGetUser: (params) => dispatch(userActions.get(params)),
    onPictureUpload: (params) => dispatch(filesActions.upload(params)),
    onGetToken: () => dispatch(userActions.getFromToken()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(UserInfo));
