
import React from 'react';

import Sidebar from '../components/layout/Sidebar';
import ListProduct from '../components/customs/RowProduct';


class Favourites extends React.Component {
  constructor(props) {
    super(props);
    this.from = this.props.location.state?.from || { pathname: '/' };
    this.state = {
      products: [
        {
          url: 'https://http2.mlstatic.com/D_NQ_NP_747546-MLA40009553712_122019-O.webp',
          title: 'Taladro Bosh',
          points: 120,
          name: 'Juan Perez',
          distance: 3
        },
        {
          url: 'https://http2.mlstatic.com/D_NQ_NP_747546-MLA40009553712_122019-O.webp',
          title: 'Podadora a motor',
          points: 140,
          name: 'Jerome Bell',
          distance: 3
        },
        {
          url: 'https://http2.mlstatic.com/D_NQ_NP_695958-MLA42146209760_062020-O.webp',
          title: 'Raqueta Pro staff',
          points: 95,
          name: 'Ronald Richards',
          distance: 3
        },
        {
          url: 'https://http2.mlstatic.com/D_NQ_NP_603746-MLA43005459805_082020-O.webp',
          title: 'Guitarra Criolla',
          points: 125,
          name: 'Darlene Robertson',
          distance: 3
        },
      ],
    }
  }
  
  componentDidMount() {
    console.log('Favourites');
  }

 
  render() {
    return (

      <main className="light-primary-bg w-full min-h-full">
        <Sidebar 
          header={{ 
            title: "Favoritos",
          }}
        />

        <div className="container bg-white shadow">
        {
              this.state.products.map((p, index) => {
                return (
                  <ListProduct 
                    key={'img' + index}
                    url={p.url}
                    title={p.title}
                    points={p.points}
                    name={p.name}
                    distance={p.distance}
                  />
                )
              })
            }
        </div>

      </main>
    ) 
  }
}

export default Favourites;
