import React from 'react';

import LayoutSmall from '../components/layout/LayoutSmall';
import { history } from '../routes';
import config from '../config';
import Icon from '../libraries/icons';
import Button from '../components/commons/Button';
import ButtonIcon from '../components/commons/ButtonIcon';

class VoluntaryPage extends React.Component {
  constructor(props) {
    super(props);
    this.from = this.props.location.state?.from || { pathname: '/' };
    this.state = {      
    }
  }
  
  componentDidMount() {
    console.log('Voluntarios');
  }

 
  render() {
    return (
      <LayoutSmall
        main={{ className: "light-primary-bg text-primary w-full"}}
        header={{ 
          className: "text-primary flex justify-between p-3 fixed",
          left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.HOME) },
          right: { icon: 'heart'}
        }}
        container={{ className: "px-0"}}
      > 
        <div className="bg-white shadow-lg">
          <div className="p-3">
            <img src="https://www.rosario.gob.ar/estilos/iframes/assets/img/logo.svg" className="h-14 mx-auto" alt="" />
          </div>
          <div className="container p-4">  
            <h1 className="h1 text-secondary mb-5">Servir el almuerzo a los niños del comedor "Sonrisas Felices"</h1>
            <div className="flex align-center justify-between">
              <p className="points big text-secondary">240 pts <small>por día</small></p>
              <div>
                <small className="text-gray-500 mr-3"> 
                  <Icon className="h-3 w-3 inline-block mr-1" name="pin" />
                  3km
                </small>
                <small className="text-primary">3/20</small>
              </div>
            </div>
            <div className="flex items-center gap-3 border-b border-gray-200 pb-4 mt-4">
              <Button
                className="btn btn-white"
                title="Mensaje"
              />
              <Button
                className="btn btn-primary flex-1"
                title="pedir prestado"
              />
            </div>
          </div>
        </div>

        <section className="container-fluid p-4">
          {/* QR */}
          <div className="p-4 text-center border-b border-gray-200 mb-5">
            <h3 className="h3 text-gray-700"> Presentá este código en el momento del voluntariado</h3>
            <a className=""> Como utilizar el código </a>
            <img src="https://esmeraldadiazaroca.com/wp-content/uploads/2011/03/auge-codigos-qr.jpg" className="w-full my-3" alt="" />
            <p className="uppercase text-gray-400">Código</p>
            <h4 className="h3 text-gray-600">743782</h4>
            <Button
              className="btn btn-lg btn-white text-red-500 mt-4"
              title="Cancelar postulación"
            />
          </div>

          <h4 className="text-secondary mb-4">Descripción</h4>
          <p className="text-gray-600">El día Domingo 27 de Septiembre se requiere la ayuda de 20 personas para ayudarnos a servir los platos con comida a chicos de 6 a 12 años que almuerzan de forma recurrente todos los días en el Comedor "Sonrisas Felices".

            Dirección: Av Dardo Rocha 2023, Rosario.
            Fecha: 27 de Septiembre
            Horario: 12 a 14 pm
            Requisito: Llevar barbijo propio. 
            (nosotros les daremos los guantes de latex)</p>
        </section>

      </LayoutSmall>
    ) 
  }
}

export default VoluntaryPage;
