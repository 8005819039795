import React from 'react';
import { Switch } from '@headlessui/react'


const SwitchboxInput = ({ input, meta, label, ...rest }) => {
  // const [enabled, setEnabled] = useState(false)

  return (
    <div className={(rest.className ? rest.className: "")}>
      <Switch.Group>
        {label && <Switch.Label>{label}</Switch.Label>}
        <Switch
          {...input}
          checked={input.value || false}
          // onChange={setEnabled}
          className={`${
            input.value ? "bg-blue-300" : "bg-gray-200"
          } relative inline-flex flex-shrink-0 h-6 w-11 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer focus:outline-none focus:shadow-outline`}
        >
          {({ checked }) => (
            <span
              className={`${
                checked ? "translate-x-5" : "translate-x-0"
              } inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full`}
            />
          )}
        </Switch>
      </Switch.Group>
      {/* {!rest.noerror && <p className="invalid-feedback">{meta.error && meta.touched && meta.error}&nbsp;</p> } */}
    </div>
  )
}

export default SwitchboxInput;