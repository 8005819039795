import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../libraries/icons';



const bottomNav = ({ ...rest }/*{ name, label, type, value, alert, setInputs }*/) => {
	// const location = useLocation();
	const menu = [
    // { icon: 'home', label: 'Dashboard', route: '/' },
    { icon: 'home', label: 'Home', route: '/' },
    { icon: 'search', label: 'Buscar', route: '/search' },
    { icon: 'add_solid', label: 'Agregar', route: '/add' },
    { icon: 'notification', label: 'Notificaciones', route: '/notification' },
    { icon: 'message', label: 'Mensajes', route: '/receipts' },
	];

	const isSelected = (route)  => {
    // return location.pathname === route;    
  }
	
	const renderMenuItem = (item, index) => {
    const { icon, label, route } = item;
    const selected = isSelected(route);
    return (
      <Link
        key={`sb${index}`}
        className={"w-full focus:text-primary hover:text-primary justify-center inline-block text-center pt-2 pb-1" + (selected ? "-selected" : "")}
        to={route}
      >
        <Icon className="h-6 w-6 mx-auto text-gray-500 mb-1" name={icon} />
        <span className="tab tab-home block text-xs">{label}</span>
      </Link>
    )
  }

  return (
		<section id="bottom-navigation" className="block fixed left-0 right-0 w-full bottom-0 z-10 bg-white shadow-lg">
			<div id="tabs" className="flex justify-between">
				{ menu.map((item, index) => renderMenuItem(item, index))}
			</div>
		</section>
  )
}

export default bottomNav;
