const routes = {
  ROUTES: {
    HOME: '/',
    LOGIN: '/login',
    SIGNUP: '/signup',
    SIGNUP_FULL: '/social/signup',
    FORGOT: '/forgot',
    VERIFY: '/verify/:hash',
    PASSWORD: '/password/:hash',
    PASSWORD_CHANGE: '/password_change',
    PROFILE: '/profile',
    USERINFO: '/user_info',
    USERSETTINGS: '/user_settings',


    SUCCESS: '/success',
    INVENTORY: '/inventory',
    LOANS: '/loans',
    RETURNS: '/returns',
    FAVOURITES: '/favourites',
    SEARCH_LIST: '/searching',
    VOLUNTARIES: '/voluntaries',
    ACTIVITIES: '/activities',
    VOLUNTARY_PAGE: '/voluntary_page',
    BENEFITS: '/benefits',
    BENEFIT_PAGE: '/benefit_page',
    GREEN_PAGE: '/green_page',
    PRODUCT_PAGE: '/product_page',
    QR_PAGE: '/qr',
    SEARCH_PAGE: '/search',
    CATEGORY_PAGE: '/category',

    HELP: '/help',
    TERMS: '/terms',
    PRIVACY: '/privacy',
    HOW_WORKS: '/how_works',
    CONTACT_US: '/contact_us',    
  }
};
export default routes;
