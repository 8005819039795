import React from 'react';
import { history } from '../../routes';
import config from '../../config';
import Button from '../commons/Button';
import ButtonIcon from '../commons/ButtonIcon';


export default function CardPost({ url, title, points, name, distance, ...rest }) {
  return (
    <div className={"card white overflow-hidden " + (rest.className ? rest.className : "")} onClick={() => history.push(config.ROUTES.PRODUCT_PAGE)}>
      <div className="flex justify-between items-center p-3">
        <div className="flex content-start">
          <div className="w-10 h-10 mr-2 overflow-hidden flex justify-center items-center rounded-full">
            <img src={url} />
          </div>
          <div>
            <h6 className="h6 text-orange-900">Darlene Robertson</h6>
            <small className="text-gray-400">2 días</small>
          </div>
        </div>
        <div className="flex items-center justify-end">
          <div className="badge badge-ghost">Ayuda comunitaria</div>
            <ButtonIcon
              className="text-gray-400 w-5 h-5"
              onClick=""
              icon="more_horizontal"
            /> 
        </div>
      </div>
      <div className="h-48 overflow-hidden flex justify-center items-center">
        <img src={url} />
      </div>
      <div className="p-3">
        <p className="text-gray-600">El día Domingo 27 de Septiembre se requiere la ayuda de 20 personas para servir los platos con comida a chicos de 6 a 12 años que almuerzan de forma recurrente todos los días en el Comedor "Sonrisas Felices".</p>
      </div>
      <div className="grid grid-cols-2 border-t border-gray-100">
        <Button
          className="btn btn-sm btn-link btn-block rounded-none"
          title="comentar"
          onClick={() => history.push(config.ROUTES.GREEN_PAGE)}
        />
        <Button
          className="btn btn-sm btn-link btn-block rounded-none"
          title="me gusta"
          onClick={() => history.push(config.ROUTES.GREEN_PAGE)}
        />
      </div>
    </div>
  )
}