import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icon from '../../libraries/icons';


export default function MenuItem({ item, location, ...rest }) {
  const { t, i18n } = useTranslation();
  if (!item) return null;
  const { icon, label, route } = item;
  const isSelected = (route, location)  => {
    return location.pathname === route;    
  }
  return (
    route ? (
      <Link
        className={"flex items-center mt-1 py-1 px-2 rounded-md text-primary-200" + (isSelected(route, location) ? "-selected" : "")}
        to={route}
      >
        {icon && <Icon className="h-7 w-7 mr-3" name={icon} />}
        <span className="uppercase">{t(label)}</span>
      </Link>
    ) : (
      // Only a title
      <div className="flex items-center mt-1 py-1 px-2 rounded-md text-primary-200">
        {icon && <Icon className="h-7 w-7 mr-3" name={icon} />}
        <span className="uppercase">{t(label)}</span>
      </div>
    )
  )
}
