import React from 'react';
import Icon from '../../libraries/icons';

export default function UserPoints({ icon, coins, ...rest })  {
  const leyend = rest.leyend ? rest.leyend : '';
  return (
    <div className="flex items-center">
      <div className={
        "inline-block "
        + (rest.className ? rest.className : "mx-auto")
      }>
        <p className="text-gray-500"><Icon className="inline w-5 h-5 mr-1 text-yellow-500" name={icon} />{coins} {leyend}</p>
      </div>
    </div>
  );
}


