import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { history } from '../../routes';
import config from '../../config';

import logo from '../../assets/img/logo.png';
import Button from '../commons/Button';
import ButtonIcon from '../commons/ButtonIcon';
import MenuItem from '../commons/MenuItem';
import UserProfileImage from '../commons/UserProfileImage';
import UserPoints from '../customs/UserPoints';

export default function SidebarMobileOnly({ header }) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [collapseShow, setCollapseShow] = React.useState("-translate-x-full");
  const user = useSelector(state => state.users.auth.user);

  const mainMenu = config.SIDEBAR.MENU.MAIN;
  const secondaryMenu = config.SIDEBAR.MENU.SECONDARY;
  const adminMenu = config.SIDEBAR.MENU.ADMIN;

  return (
    <nav className="bg-white z-50 py-3 px-3 flex items-center h-16">
      <div className="flex-no-wrap flex flex-wrap items-center justify-between w-full">
        <div className="w-10 text-center">
          <button
            className="cursor-pointer bg-teal-100 text-primary rounded-full mr-3 text-xl leading-none p-1 border border-solid border-transparent text-center"
            type="button"
            onClick={() => setCollapseShow("translate-x-0")}
          >
            <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
        { header && header.title ? (
            <h2 className="h2 text-primary-100">{header.title}</h2>
          ) : header && header.logo && (
            <div className="mx-auto -pl-4">
              <img src={logo} alt="" width="100px" />
            </div>
          )
        }
        <div className="w-10 text-center">
          { header && header.right && (
            <ButtonIcon
              className={header.right.className ? header.right.className : "h-6 w-6 text-white"}
              onClick={header.right.action}
              icon={header.right.icon}
            />
          )}
        </div>
      </div>
      <div
        className={
          "z-50 transform top-0 left-0 bottom-0 w-64 bg-primary fixed h-full overflow-auto ease-in-out transition-all duration-300 p-3 flex justify-between flex-col " +
          collapseShow
        }
      >
        <div className="md:min-w-full block pb-4 mb-4">
          <ButtonIcon
            className="cursor-pointer p-0 text-primary-200 text-xl leading-none bg-transparent block ml-auto h-8 w-8 text-white"
            onClick={() => setCollapseShow("-translate-x-full")}
            icon="close"
          />
          <div className="text-center mt-4 border-b border-solid border-white border-opacity-20 pb-3 mb-3">
            <UserProfileImage data={user} />
            <Button
              className="btn-sm bg-yellow-200 border-yellow-200 text-secondary mx-auto capitalize mt-3 px-6"
              title={t("Profile")}
              onClick={() => history.push(config.ROUTES.PROFILE)}
            />
          </div>
          <div className="flex flex-col md:flex-col md:min-w-full text-primary-200">
            { mainMenu && mainMenu.map((item, index) => <MenuItem key={index} item={item} location={location} />)}
          </div>
        </div>
        <div className="flex flex-col md:flex-col md:min-w-full border-t text-sm border-solid border-white border-opacity-20 pt-4">
          { secondaryMenu && secondaryMenu.map((item, index) => <MenuItem key={index} item={item} location={location} />)}
        </div>
      </div>
    </nav>
  );
}
