import React from 'react';
import Button from '../commons/Button';
import { history } from '../../routes';
import config from '../../config';


export default function CardBenefit({url, title, points, ...rest }) {
  return (
    <div className={"card white overflow-hidden " + (rest.className ? rest.className : "")}>
      <div className="h-40 overflow-hidden flex justify-center items-center">
        <img src={url} />
      </div>
      <div className="p-3 w-full">
        <h6 className="h6 text-secondary mb-4">{title}</h6>
        <p className="points">{points} pts</p>
        <Button
          className="btn btn-sm btn-block btn-primary capitalize mt-2"
          title={"Adquirir"}
          onClick={() => history.push(config.ROUTES.BENEFIT_PAGE)}
        />
      </div>
    </div>
  )
}