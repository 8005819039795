import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form'

import userActions from '../../context/users/actions';
import filesActions from '../../context/files/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import { composeValidators, validateEmail, validateIsfilled, isEmptyObject, getTimeZone } from '../../libraries/utils';

import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import TextInput from '../../components/forms/TextInput';
import CheckboxInput from '../../components/forms/CheckboxInput';

class UserFullSignup extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      submitting: false,
      selectedFile: null,
      profile_image: null,
      signupData: {},
      filledFields: false,
    }
  }
  
  componentDidMount() {
    console.log('USER FULL SIGNUP PAGE');
    if (isEmptyObject(this.props.location.state)) { 
      history.push(config.ROUTES.LOGIN);
    } else {
      const social = this.props.location.state.user;
      console.log('USER FROM SOCIAL', social);
      const user = {
        provider: social._provider,
        social_id: social._profile.id,
        first_name: social._profile.firstName,
        last_name: social._profile.lastName,
        email: social._profile.email,
        profile_image: social._profile.profilePicURL,
      }
      console.log('USER', user);
      this.setState({ signupData: user, filledFields: true });
    }    
  }

  onSubmit = async values => {
    console.log('ON SUBMIT', values);
    this.setState({submitting: true, signupData: {...values}});
    return;
    const data = { ...values, time_zone: getTimeZone(), user_type: config.USER_TYPE.REGULAR }
    this.props.onRegister(data).then(()=> {
      console.log('AFTER SAVE');
      const error = this.props.user.error;
      if (error) { 
        this.setState({submitting: false});
        notify(this.t(error.message));
      } else {
        // history.push(config.ROUTES.PASSWORD.replace(':hash', this.props.user.item.hash));
        history.push(config.ROUTES.PASSWORD.replace(':hash', this.props.user.item.hash));
      }
    });
    // this.setState({ submitting: true });
    // await this.props.onUpdate(values);
    // console.log('AFTER UPDATE');
    // const error = this.props.user.error;
    // if (error) {
    //   this.setState({ submitting: false });
    //   notify(this.t(error.message));
    // } else {
    //   this.gotoBack();
    // }
  }

  onFileChange = async event => {
    this.setState({ submitting: true });
    console.log('ON FILE CHANGE');
    this.setState({ selectedFile: event.target.files[0] });
    await this.onFileUpload(event.target.files[0]);
    console.log('ON FILE CHANGE FINISH');
    this.setState({ submitting: false });
  }

  onFileUpload = async (file) => {
    console.log('FILE', file.name, file.type);
    const formData = new FormData();
    formData.append(
      "myFile",
      file,
      file.name
    )
    await this.props.onPictureUpload(formData);
    console.log('FILES', this.props.files);
    const error = this.props.files.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      this.setState({ profile_image: this.props.files.files.fileInfo.location });
      this.props.onUpdate({ id: this.state.user.id, profile_image: this.props.files.files.fileInfo.location }).then(()=> {
        console.log('AFTER SAVE PICTURE');
        const error = this.props.user.error;
        if (error) notify(this.t(error.message));
      });       
    }
  }

  render() {
    const { signupData, filledFields } = this.state;
    if (this.state.profile_image) {
      console.log('AVATAR UPLOADED');
      signupData.profile_image = this.state.profile_image;
    }
    const required = value => (validateIsfilled(value) ? undefined : this.t("This field is required"))
    const email = value => (validateEmail(value) ? undefined : this.t("Not a valid email"))

    return (
      <LayoutSmall
        main={{ className: "bg-primary text-primary-100"}}
        header={{ 
          logo: true,
          left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.LOGIN) }
        }}
        container={{ className: "px-0"}}
      >
        <ToastContainer/>
        <section className="bg-primary text-center px-3 pt-2 pb-8"> 
          <div className="center mx-auto">
            <div className="px-4 py-3 rounded-lg text-center">
              <div className="rounded-full bg-gray-500 overflow-hidden flex items-center text-center m-auto w-28 h-28">
                {signupData && signupData.profile_image ? (
                  <img className="w-full h-auto" src={signupData && signupData.profile_image} alt="" />
                ) : (
                  <span className="m-auto text-white text-2xl">{signupData && signupData.name}</span>
                )}
              </div>
              <label className="cursor-pointer mt-6">
                {(signupData && !signupData.profile_image) && (
                  <>
                    <span className="btn btn-secondary btn-md mt-5 inline-block">{this.t("Upload a photo")}</span>
                    <input type='file' className="hidden" multiple="multiple" accept="accept" onChange={this.onFileChange} />
                  </>
                )}
              </label>
            </div>            
          </div>
        </section>
        <section className="px-4 -mt-5">
          <Form 
            initialValues={signupData || {}}
            onSubmit={this.onSubmit}
          >
            {({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto">
                <div className="card white py-3">
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field name="first_name" component={TextInput} placeholder={this.t("First name")} label={this.t("First name")}
                      validate={required}
                      className="text-gray-700"
                      disabled={filledFields}
                    />
                  </div>
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field name="last_name" component={TextInput} placeholder={this.t("Last name")} label={this.t("Last name")}
                      validate={required}
                      className="text-gray-700"
                      disabled={filledFields}
                    />
                  </div>
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field name="email" component={TextInput} placeholder={this.t("Email")} label={this.t("Email")}
                      validate={composeValidators(required, email)}
                      className="text-gray-700"
                      disabled={filledFields}
                    />
                  </div>
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field name="phone" component={TextInput} placeholder={this.t("Phone number")} label={this.t("Phone number")}
                      validate={required}
                      className="text-gray-700"
                    />
                  </div>
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field name="terms" component={CheckboxInput} label={this.t("I accept terms and conditions")}
                      validate={required}
                      labelClassName="text-gray-700"
                      type="checkbox"
                    />
                  </div>
                  <div className="w-full px-3">
                    <Button
                      className="btn btn-lg btn-block btn-secondary"
                      title={this.t("Save")}
                      onClick={handleSubmit}
                      disabled={this.state.submitting || pristine}
                    />
                  </div>
                </div>
              </form>
            )}
          </Form>
        </section>
      </LayoutSmall>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    files: state.files,
    user: state.users.current,
    auth: state.users.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRegister: (params) => dispatch(userActions.saveOrUpdate(params, 'register')),
    onPictureUpload: (params) => dispatch(filesActions.upload(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(UserFullSignup));
