import React from 'react';

import LayoutSmall from '../components/layout/LayoutSmall';
import { history } from '../routes';
import config from '../config';
import Icon from '../libraries/icons';
import Button from '../components/commons/Button';


class ProductPage extends React.Component {
  constructor(props) {
    super(props);
    this.from = this.props.location.state?.from || { pathname: '/' };
    this.state = {      
    }
  }
  
  componentDidMount() {
    console.log('BenefitPage');
  }

 
  render() {
    return (
      <LayoutSmall
        main={{ className: "light-primary-bg text-primary w-full"}}
        header={{ 
          className: "bg-white text-primary flex justify-between p-3",
          left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.HOME) },
          right: { icon: 'heart'}
        }}
        container={{ className: "px-0"}}
      > 
        <div className="bg-white shadow">
          <div className="container p-4">
            <img src="https://http2.mlstatic.com/D_NQ_NP_747546-MLA40009553712_122019-O.webp" alt="" width="100%" />
          </div>
        </div>

        <section className="container-fluid p-4 ">
          <div className="flex items-center justify-between">
              <h1 className="h1 text-secondary">Podadora a motor</h1>
              <small className="text-gray-500">3km</small>
            </div>
            <div className="flex items-center">
              <small className="text-gray-500 mr-2">
                de 
                <span className="text-primary">Jerome Bell</span>
              </small>
              <Icon className="h-4 w-4 sroke-current text-yellow-500 inline-block m-0.5" name="star" />
              <Icon className="h-4 w-4 sroke-current text-yellow-500 inline-block m-0.5" name="star" />
              <Icon className="h-4 w-4 sroke-current text-gray-300 inline-block m-0.5" name="star" />
              <Icon className="h-4 w-4 sroke-current text-gray-300 inline-block m-0.5" name="star" />
              <Icon className="h-4 w-4 sroke-current text-gray-300 inline-block m-0.5" name="star" />
              <a className="text-primary underline ml-auto">Ver comentarios</a>
            </div>
            <p className="points big text-secondary mt-5 mb-1">140 pts <small>por día</small> </p>
            <a className="text-primary underline">consultar disponibilidad</a>

            <div className="flex items-center gap-3 border-b border-gray-200 pb-4 mt-4">
              <Button
                className="btn btn-white"
                title="Mensaje"
              />
              <Button
                className="btn btn-primary flex-1"
                title="pedir prestado"
              />
            </div>
          </section>

          <section className="p-4">
            <h4 className="text-secondary mb-4">Descripción</h4>
            <p className="text-gray-600">El día Domingo 27 de Septiembre se requiere la ayuda de 20 personas para ayudarnos a servir los platos con comida a chicos de 6 a 12 años que almuerzan de forma recurrente todos los días en el Comedor "Sonrisas Felices".

              Dirección: Av Dardo Rocha 2023, Rosario.
              Fecha: 27 de Septiembre
              Horario: 12 a 14 pm
              Requisito: Llevar barbijo propio. 
              (nosotros les daremos los guantes de latex)</p>
          </section>

      </LayoutSmall>
    ) 
  }
}

export default ProductPage;
