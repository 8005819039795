import React from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSwitcher() {
  const { t, i18n } = useTranslation();
  return (
    <div className="select">
      <select
        disabled={true}
        value={i18n.language}
        onChange={(e) => {
          i18n.changeLanguage(e.target.value)
        }}
      >
        <option value="en">{t("English")}</option>
        <option value="es">{t("Spanish")}</option>
      </select>
    </div>
  );
}

export default LanguageSwitcher;

