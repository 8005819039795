import config from './routes';

const customs =  {
  COMPANY: {
    SHORT_NAME: 'SHARYCO',
    LONG_NAME: 'SHARYCO'
  },
  SUPPORTED_LANGUAGES: ['en', 'es'],
  DEFAULT_LANGUAGE: 'es',
  MONEY_SYMBOL: '$',
  MONEY_CODE: 'u$s',
  MONEY_POSITION: 'prefix', //'sufix'
  MONEY_SPACE: true,
  CURRENCIES: ['USD','ARS'],
  CURRENCY: 'ARS',
  LOCALE: 'es-ES',
  USER_TYPE: {
    REGULAR: 'regular',
    ADVANCED: 'advanced'
  },
  USER_ACCOUNTS_INTEGRATED: true,
  USER_ACCOUNTS: {
    TYPES: {
      OWNER: 'owner',
      GUEST: 'guest'
    },
    STATUS: {
      ACTIVE: 'active',
      INACTIVE: 'inactive',
      REVOKED: 'revoked'
    }
  },  
  POINTS: {
    unit : 'lts',
    factor: 1,
  },
  TYPES: {
    SERVICES: 'services',
  },
  TRANSACTIONS: {
    TYPE: 'wash',
    SUB_TYPE: null,
    STATUS: {
      BOOKED: 'booked',
      CANCELED: 'canceled',
      ASSIGNED: 'assigned',
      REVIEW: 'review',
      COMPLETED: 'completed'
    }
  },
  OPTIONS: {
    VEHICLES: {
      TYPES: ['car', 'big_truck', 'suv', 'motocycle', 'bicycle'],
      BRANDS: ['ford', 'volkswagen', 'renault', 'toyota', 'honda', 'fiat', 'bmw', 'mercedes benz', 'nissan', 'peugeot', 'chevrolet', 'otra'],
      COLORS: ['White', 'Black', 'Gray', 'Red', 'Blue', 'Green', 'Yellow', 'Other'],
      SERVICES_VARIANTS_RELATION: 'enum:vehicles:types',
      VALIDATE_NO_BRAND: ['bicycle'],
      VALIDATE_NO_MODEL: ['bicycle'],
      VALIDATE_NO_PATENT: ['bicycle'],
    },
    
  },
  SIDEBAR: {
    MENU: {
      MAIN: [
        { icon: 'pin', label: 'Inventario', route: config.ROUTES.HOME },
        { icon: 'pin', label: 'Prestamos', route: config.ROUTES.VEHICLES },
        { icon: 'pin', label: 'Devoluciones', route: config.ROUTES.WASHES },
        { icon: 'pin', label: 'Favoritos', route: config.ROUTES.LOCATIONS },
        { icon: 'pin', label: 'Yo Busco', route: config.ROUTES.LOCATIONS },
        { icon: 'pin', label: 'Voluntariados', route: config.ROUTES.LOCATIONS },
        { icon: 'pin', label: 'Mi actividad', route: config.ROUTES.LOCATIONS },
      ],
      SECONDARY: [
        // { icon: 'my_washes', label: 'Wash types', route: config.ROUTES.WASH_TYPES },
        { /* icon: 'help',*/ label: 'How works?', route: config.ROUTES.HOW_WORKS },
        { /*icon: 'phone',*/ label: 'Contact us', route: config.ROUTES.CONTACT_US },
        { /*icon: '',*/ label: 'Terms and conditions', route: config.ROUTES.TERMS },
      ],
      ADMIN: [
      ],
    },
  },
  SLIDER: {
    SHOW: true,
    CONTENT: [
      {
        title: 'slider.title_1',
        body: 'slider.body_1',
        button: 'slider.button_1',
      },
      {
        title: 'slider.title_2',
        body: 'slider.body_2',
        button: 'slider.button_2',
      },
      {
        title: 'slider.title_3',
        body: `slider.body_3`,
        button: 'slider.button_3',
      },
    ]
  }
};
export default customs;