
import React  from 'react';
import Sidebar from './SidebarMobile';
import Loader from '../commons/Loader';

export default function LayoutWithSidebar({ main, header, container, loading, children }) {
  return (
    <main className={"flex flex-col h-screen bg-base-100 " + (main?.className ? main.className : "") }>
      <Sidebar header={header} />
      <div className={"flex-1 overflow-y-auto " + (container?.className ? container.className : "px-0")}>
        {loading ? (
          <Loader size="full"/>
        ) : (
          children
        )}
      </div>
    </main>
  )
}