import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form'

import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import { composeValidators, validateEmail, validateIsfilled, getTimeZone } from '../../libraries/utils';

import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import TextInput from '../../components/forms/TextInput';
import CheckboxInput from '../../components/forms/CheckboxInput';

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      submitting: false,
      signupData: {},
    }
  }
  
  componentDidMount() {
    console.log('SIGNUP PAGE');
  }
 
  onSubmit = async (values) => {
    this.setState({submitting: true, signupData: {...values}});
    const data = { ...values, time_zone: getTimeZone(), user_type: config.USER_TYPE.REGULAR }
    this.props.onRegister(data).then(()=> {
      console.log('AFTER SAVE');
      const error = this.props.user.error;
      if (error) { 
        this.setState({submitting: false});
        notify(this.t(error.message));
      } else {
        history.push(config.ROUTES.PASSWORD.replace(':hash', this.props.user.item.hash));
      }
    });
  }

  onTerms = () => {
    history.push(config.ROUTES.TERMS);
  }

  onLogin = () => {
    history.push(config.ROUTES.LOGIN);
  }

  render() {
    const required = value => (validateIsfilled(value) ? undefined : this.t("This field is required"))
    const email = value => (validateEmail(value) ? undefined : this.t("Not a valid email"))

    return (
      <LayoutSmall
        main={{ className: "bg-primary text-primary-100"}}
        header={{ logo: true }}
      >
        <ToastContainer/>
        <h2 className="h2 text-primary-100 text-center mb-5 mt-5">{this.t("Register")}</h2>
        <Form
          initialValues={this.state.signupData || {}}
          onSubmit={this.onSubmit}
        >
          {({ handleSubmit, form, submitting, pristine, values }) => (
          <>
            <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto px-4">
              <div className="w-full mb-3 md:mb-0">
                <Field name="first_name" component={TextInput} placeholder={this.t("First name")} validate={required}/>
              </div>
              <div className="w-full mb-3 md:mb-0">
                <Field name="last_name" component={TextInput} placeholder={this.t("Last name")} validate={required}/>
              </div>
              <div className="w-full mb-3 md:mb-0">
                <Field name="email" component={TextInput} placeholder={this.t("Email")} validate={composeValidators(required, email)}/>
              </div>
              <div className="w-full mb-3 md:mb-0">
                <Field name="phone" component={TextInput} placeholder={this.t("Phone number")} validate={required}/>
              </div>
              <div className="w-full mb-3 md:mb-0">
                <Field name="terms" component={CheckboxInput} label={this.t("I accept terms and conditions")}
                  validate={required}
                  className="text-primary-100"
                  type="checkbox"
                />
              </div>
              <div className="w-full">
                <Button
                  className="btn btn-lg btn-block btn-secondary"
                  title={this.t("Next")}
                  onClick={handleSubmit}
                  disabled={this.state.submitting || pristine}
                />
              </div>
            </form>
          </>
        )}
        </Form>
        <div className="w-full px-3 mt-5">
          <Button
            className="btn btn-link btn-block underline"
            title={this.t("Terms and conditions")}
            onClick={this.onTerms}
          />
        </div>
        <div className="mt-2 p-2 flex justify-center items-center">
          <p className="text-primary-100 text-center mr-3">{this.t("Has an account?")}</p>
          <Button
              className="btn btn-link mb-0 underline"
              title={this.t("Login with your user")}
              onClick={this.onLogin}
            />
        </div>
      </LayoutSmall>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    user: state.users.current
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRegister: (params) => dispatch(userActions.saveOrUpdate(params, 'register')),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Signup));
