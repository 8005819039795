import React from 'react';
import { withTranslation } from 'react-i18next';
import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';


class InfoPrivacy extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
    }
  }
  
  componentDidMount() {
    console.log('TERMS PAGE');
  }

  onExit = async values => {
    history.push(config.ROUTES.HOME)        
  }
 
  render() {
    return (
      <LayoutSmall
        header={{ 
          className: "bg-primary",
          title: this.t("Privacy policies"),
          left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.HOME) }
        }}      
        main={{ 
          className: "light-primary-bg text-gray-700",
        }}
      >
        <p className="mb-2">Las políticas de privacidad entrarán en vigencia desde su aceptación para los nuevos usuarios. La privacidad de la información de los usuarios es muy importante para ELEX. Por esa esa razón se toman las precauciones y recaudos para resguardar información, utilizando los mecanismos de seguridad informática de protección de la información más completos y eficaces. -
            </p>
            <p className="mb-2">Como parte normal de nuestra actividad recogemos y, en algunos casos, revelamos información sobre nuestros usuarios y visitantes de ELEX. -
            </p>
            <p className="mb-2">Este documento es parte integrante de los términos y condiciones de ELEX. Con la aceptación de los Términos y Condiciones Generales en el momento de la inscripción el usuario acepta las disposiciones aquí contenidas. -
            </p>
            <p className="mb-2">Los usuarios podrán ejercitar los derechos de acceder, rectificar, cancelar y actualizar sus Datos Personales, incluyendo su dirección de e-mail, así como a oponerse al tratamiento de la misma y a ser informado de las cesiones llevadas a cabo, todo ello de conformidad a lo dispuesto en la normativa aplicable. Los usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de los Datos Personales facilitados, y se comprometen a mantenerlos debidamente actualizados. -
            </p>
            <p className="mb-2">Una vez registrado en ELEX, el usuario podrá revisar y cambiar la información que nos ha enviado durante el proceso de inscripción incluyendo:
            </p>
            <p className="mb-2"> •	El Seudónimo y dirección de correo electrónico. Sin perjuicio de los cambios que realice, ELEX conservará los Datos Personales anteriores por motivos de seguridad y control del fraude. -
            </p>
            <p className="mb-2"> •	La información de la inscripción como: empresa, domicilio, ciudad, código postal, número de teléfono, correo electrónico, etc.-
            </p>
            <p className="mb-2"> •	La clave. -
            </p>
            <p className="mb-2">En determinados casos, se mantendrá en los archivos de ELEX, los Datos Personales que hayan pedido que sean retirados, y ello con la finalidad de resolver disputas o reclamaciones, detectar problemas o incidencias y solucionarlos, y dar cumplimiento a lo dispuesto en los Términos y Condiciones Generales por el período de tiempo que determine la normativa aplicable. En cualquier caso, los Datos Personales de un usuario no será inmediatamente retirada de los archivos por motivos legales y técnicos, incluyendo sistemas de soportes de seguridad. Por tanto, no se debe esperar que todos los Datos Personales sean definitivamente borrados de nuestras bases de datos. -
            </p>
            <p className="mb-2">Los usuarios deben actualizar sus Datos Personales periódicamente conforme vaya cambiando para que los otros usuarios puedan ubicarlos cuando realicen una operación. Para hacer cualquier modificación en la información que suministrada en el momento de la suscripción. Para recibir mayor información sobre la confidencialidad de los Datos Personales, así como para el caso que deseen ejercer derechos de acceder, rectificar, cancelar u oponerte al tratamiento de Datos Personales, deberá contáctanos por correo electrónico o postal. -
            </p>

            <p className="mb-2">Lo primero que se debe hacer para disfrutar de los servicios de ELEX es suscribirse, suministrando ciertos datos personales ("Datos Personales") completos y exactos. Podremos solicitar, recabar y almacenar los siguientes Datos Personales: apodo o seudónimo para operar en el sitio de ELEX, nombre, número de documento o identificación válida, información física de contacto (como número de teléfono domicilio, dirección de e-mail, etc.). -
            </p>

            <p className="mb-2">El Usuario que se registre en ELEX a través de su cuenta personal en una red social ("Cuenta Personal"), o de cualquier modo acceda a ELEX a través de su Cuenta Personal, consiente expresamente que ELEX:
            </p>

            <p className="mb-2"> •	Tenga acceso, en cualquier momento, a la totalidad de la información contenida en su Cuenta Personal, incluyendo en particular, pero sin limitación, a sus Datos Personales, información sobre sus intereses, gustos, contactos y cualquier otro contenido alojado en su Cuenta Personal;
            </p>
            <p className="mb-2"> •	Incluya en dicha cuenta, mensajes, fotografías, videos y cualquier otro tipo de contenido; y
            </p>
            <p className="mb-2"> •	Envíe al correo electrónico del Usuario vinculado a la Cuenta Personal, información o mensajes con la finalidad descripta en la sección "Finalidad del tratamiento de los Datos Personales" abajo. -
             </p>

            <p className="mb-2">Se acuerda expresamente que en cualquier momento el usuario registrado en ELEX podrá solicitar la baja o cierre de su solicitud de registración, y/o el cierre de su cuenta o eliminación de su cuenta e información de la base de datos de ELEX. -
            </p>

            <p className="mb-2">Por favor revisa la sección "Medios para ejercer los derechos de acceso, cancelación y rectificación de los Datos Personales" abajo. -
            </p>
            <p className="mb-2">ELEX recoge y almacena automáticamente cierta información sobre la actividad de los usuarios y visitantes dentro de su sitio web. Tal información puede incluir la URL de la que provienen (estén o no en nuestro sitio web), a qué URL acceden seguidamente (estén o no en nuestro sitio web), qué navegador están usando, y sus direcciones IP. También las páginas visitadas, las búsquedas realizadas, las publicaciones, compras o ventas, calificaciones y réplicas ingresadas, mensajes en los foros, entre otra información podrá ser almacenada y retenida. -
            </p>
            <p className="mb-2">ELEX accede a las listas de contactos de los dispositivos móviles utilizados por los usuarios para proveer sus servicios. Esa información la utilizará únicamente para localizar y señalar números de teléfonos celulares y/o correos electrónicos de otros usuarios. A su vez, los usuarios tienen autorización para compartir dicha información con ELEX. -
            </p>
            <p className="mb-2">En cuanto a los potenciales usuarios, ELEX solo almacenará números de teléfonos celulares y correos electrónicos. -
            </p>
            <p className="mb-2">Si los usuarios nos envían correspondencia sean correos electrónicos o cartas, o si otros usuarios o terceros nos envían correspondencia sobre las actividades o sobre los mensajes de otros usuarios en nuestro sitio, podemos recoger y almacenar tales Datos Personales, incluyendo direcciones de e-mail. -
            </p>

            <h3  className="h3 mb-1 mt-5">Finalidad del tratamiento de los Datos Personales.</h3>

            <p className="mb-2">Para suministrar un excelente servicio y para que los usuarios puedan realizar operaciones en forma ágil y segura, ELEX requiere cierta información de carácter personal, incluyendo dirección de e-mail. La recolección de información nos permite ofrecer a los usuarios servicios y funcionalidades que se adecuen mejor a sus necesidades y personalizar nuestros servicios para hacer que sus experiencias con ELEX sean lo más cómodas posible. -
            </p>
            <p className="mb-2">Los Datos Personales que recabamos se destinan a las siguientes finalidades:
            </p>
            
            <p className="mb-2"> •	Ayudar al comprador y vendedor a entrar en contacto directo en la oportunidad que corresponda según la modalidad de la compra. En este caso, ELEX suministrará a los interesados sus Datos Personales (nombre, teléfonos, localidad y correo electrónico), a través de correo electrónico o a través del sitio. La información así conocida por el comprador o el vendedor, sólo podrá ser utilizada a efectos de concluir la operación originada en ELEX y no deberá ser empleada por ninguno de ellos con fines publicitarios o promocionales u otras actividades no relacionadas con ELEX, salvo la expresa autorización del usuario. –
            </p>
            <p className="mb-2"> •	Desarrollar estudios internos sobre los intereses, comportamientos y demografía de los usuarios para comprender mejor sus necesidades e intereses y ofrecer mejores servicios o proveerles información relacionada. –
            </p>
            <p className="mb-2"> •	Mejorar nuestras iniciativas comerciales y promocionales y analizar las páginas visitadas las búsquedas realizadas por los usuarios, para mejorar nuestra oferta de contenidos y artículos, personalizar dichos contenidos, su presentación y servicios. –
            </p>
            <p className="mb-2"> • Enviar información o mensajes por e-mail sobre nuevos servicios, mostrar publicidad o promociones, banners, de interés para nuestros usuarios, noticias sobre ELEX, además de la información expresamente autorizada en la sección de preferencias. Si el usuario lo prefiere, puede solicitar que lo excluyan de las listas para el envío de información promocional o publicitaria. Por favor aprende a hacerlo la sección "Cambio de preferencias de e-mail" abajo. –
            </p>
            <p className="mb-2"> • Suministrar los Datos Personales de los usuarios a las entidades que intervengan en la resolución de disputas entre los mismos, tales como: Compañías de Seguros, Amigables Componedores o Tribunales de Arbitraje o tribunales competentes para solucionar tales disputas. -
            </p>

              <h3  className="h3 mb-1 mt-5">Confidencialidad de los Datos Personales.</h3>
              <p className="mb-2">
                Una vez suscripto en su sitio Web, ELEX no venderá, alquilará o compartirá los Datos Personales excepto en las formas establecidas en este Aviso de Privacidad. Sin perjuicio de ello, el usuario consiente en forma expresa que ELEX transfiera total o parcialmente los Datos Personales a cualquiera de las sociedades controladas, controlantes y/o vinculadas con ELEX, a cualquier título y en el momento, forma y condiciones que estime pertinentes. Haremos todo lo que esté a nuestro alcance para proteger la privacidad de la información. Puede suceder que en virtud de órdenes judiciales, o de regulaciones legales, nos veamos compelidos a revelar información a las autoridades o terceras partes bajo ciertas circunstancias, o bien en casos que terceras partes puedan interceptar o acceder a cierta información o transmisiones de datos en cuyo caso ELEX no responderá por la información que sea revelada. -
              </p>

              <h3  className="h3 mb-1 mt-5">Clave Personal.</h3>
              <p className="mb-2">Para acceder a los servicios reservados únicamente para los usuarios debidamente inscritos los usuarios dispondrán de una clave personal. Con ella podrán comprar, vender. Los usuarios deben mantener esta clave bajo absoluta confidencialidad y, en ningún caso, deberán revelarla o compartirla con otras personas. -
              </p>
              <p className="mb-2">El usuario será responsable de todos los actos que tengan lugar mediante el uso de su Seudónimo y Clave, lo que incluye hacerse cargo del pago de las tarifas que eventualmente se devenguen o por los perjuicios que puedan sufrir otros usuarios por tal motivo. Si por cualquier razón un usuario creyera que alguien puede conocer su clave, deberá modificarla. -
              </p>

              <h3  className="h3 mb-1 mt-5">Menores de Edad.</h3>

              <p className="mb-2">Nuestros servicios sólo están disponibles para aquellas personas que tengan capacidad legal para contratar. Por lo tanto, aquellos que no cumplan con esta condición deberán abstenerse de suministrar Datos Personales para ser incluidos en nuestras bases de datos. Sin embargo, pueden hacerlo a través de los padres o tutores. -
              </p>
              <h3  className="h3 mb-1 mt-5">El uso de los Datos Personales por otros Usuarios.</h3>

              <p className="mb-2">Para facilitar la interacción entre todos los miembros de la comunidad de ELEX, nuestro servicio permite un acceso limitado a ciertos datos de contacto del resto de usuarios, tales como Nombre de usuario, Teléfonos, Ciudad y dirección de correo electrónico. Los usuarios sólo podrán utilizar los Datos Personales de otros usuarios obtenidos en el sitio para: (a) comunicaciones relacionadas con ELEX que no constituyan comunicaciones comerciales no solicitadas, (b) utilizar servicios ofrecidos en ELEX (por ejemplo: depósito, seguros, envío o transporte y reclamaciones sobre fraude), y (c) cualquier otra finalidad a la que el usuario correspondiente consienta expresamente una vez le hayan sido comunicadas previamente la información legalmente requerida.-
              </p>
              <p className="mb-2">Bajo ninguna circunstancia, se debe comunicar Datos Personales o dirección de correo electrónico de otro usuario a ningún tercero sin nuestro consentimiento y el del usuario afectado. No se debe agregar a la agenda de direcciones de correo electrónico (física o electrónica) los datos de ningún usuario de ELEX, ni siquiera los datos de quienes hayan adquirido algún artículo ofrecido en ELEX, sin que medie el consentimiento expreso de tal usuario. -
              </p>
              
              <h3  className="h3 mb-1 mt-5">Cookies.</h3>

              <p className="mb-2">El usuario y el visitante del sitio Web de ELEX conoce y acepta que ELEX podrá utilizar un sistema de seguimiento mediante la utilización de cookies (las "Cookies"). Las Cookies son pequeños archivos que se instalan en el disco rígido, con una duración limitada en el tiempo que ayudan a personalizar los servicios. También ofrecemos ciertas funcionalidades que sólo están disponibles mediante el empleo de Cookies. Las Cookies se utilizan con el fin de conocer los intereses, el comportamiento y la demografía de quienes visitan o son usuarios de ELEX y de esa forma, comprender mejor sus necesidades e intereses y darles un mejor servicio o proveerle información relacionada. También usaremos la información obtenida por intermedio de las Cookies para analizar las páginas navegadas por el visitante o usuario, las búsquedas realizadas, mejorar nuestras iniciativas comerciales y promocionales, noticias sobre ELEX, perfeccionar nuestra oferta de contenidos y artículos, personalizar dichos contenidos, presentación y servicios; también podremos utilizar Cookies para promover y hacer cumplir las reglas y seguridad del sitio. ELEX podrá agregar Cookies en los e-mails que envíe para medir la efectividad de las promociones. -
              </p>
              <p className="mb-2">Utilizamos adicionalmente las Cookies para que el usuario no tenga que introducir su clave tan frecuentemente durante una sesión de navegación, también para contabilizar y corroborar las inscripciones, la actividad del usuario y otros conceptos para acuerdos comerciales, siempre teniendo como objetivo de la instalación de las Cookies, el beneficio del usuario que la recibe, y no será usado con otros fines ajenos a ELEX. -
              </p>
              <p className="mb-2">Se establece que la instalación, permanencia y existencia de las Cookies en el computador del usuario depende de su exclusiva voluntad y puede ser eliminada de su computador cuando el usuario así lo desee. Para saber cómo quitar las Cookies del sistema es necesario revisar la sección Ayuda (Help) del navegador. 
              </p>
              <p className="mb-2">Adicionalmente, se pueden encontrar Cookies u otros sistemas similares instalados por terceros en ciertas páginas de ELEX. Por ejemplo, al navegar por una página creada por un usuario, puede que exista una Cookie emplazada en tal página. –
              </p>
              <p className="mb-2">Se aclara expresamente que este Aviso de Privacidad cubre la utilización de Cookies por este sitio y no la utilización de Cookies por parte de anunciantes. Nosotros no controlamos el uso de Cookies por terceros. -</p>

              <h3  className="h3 mb-1 mt-5">Spam.</h3>
              <p className="mb-2">Dentro de las categorías para compraventa la inclusión del teléfono del vendedor en la publicación. En la categoría Servicios el Vendedor deberá incluir en la publicación su dirección de correo electrónico y su teléfono, y será optativa la publicación de su domicilio y de la url de su Sitio Web. -
              </p>
              <p className="mb-2">El Vendedor sólo podrá ingresar los datos mencionados, según corresponda, dentro del campo específico al momento de publicar el artículo. Sin embargo, en ningún caso deberá incluirlo en la descripción o en el título de su publicación, ni en ningún otro lugar fuera del campo específico. -
              </p>
              <p className="mb-2">Tampoco podrá el Vendedor incluir ningún otro dato personal dentro o fuera del campo indicado. -
              </p>
              <p className="mb-2">ELEX no se hace responsable del uso que el vendedor, el comprador, cualquier usuario u otra persona pudieran hacer de la información publicada. -
              </p>

              <h3  className="h3 mb-1 mt-5">Orden de autoridades competentes - Requerimientos Legales.</h3>

              <p className="mb-2">ELEX coopera con las autoridades competentes y con otros terceros para garantizar el cumplimiento de las leyes, por ejemplo, en materia de protección de derechos de propiedad industrial e intelectual, prevención del fraude y otras materias. -
              </p>
              <p className="mb-2">ELEX podrá revelar los Datos Personales de sus usuarios bajo requerimiento de la autoridades judiciales o gubernamentales competentes para efectos de investigaciones conducidas por ellas, aunque no exista una orden ni una citación ejecutiva o judicial, o por ejemplo (y sin limitación a este supuesto) cuando se trate de investigaciones de carácter penal o de fraude o las relacionadas con piratería informática o la violación de derechos de autor. En tales situaciones, ELEX colaborará con las autoridades competentes con el fin de salvaguardar la integridad y la seguridad de la Comunidad y la de sus usuarios. -
              </p>
              <p className="mb-2">ELEX puede (y los usuarios lo autorizan expresamente) comunicar cualquier Dato Personal sobre sus usuarios con la finalidad de cumplir la normativa aplicable y cooperar con las autoridades competentes en la medida en que discrecionalmente lo entendamos necesario y adecuado en relación con cualquier investigación de un ilícito o un fraude, infracción de derechos de propiedad industrial o intelectual, u otra actividad que sea ilegal o que pueda exponer a ELEX o a sus usuarios a cualquier responsabilidad legal. Además, podemos (y los usuarios lo autorizan expresamente) comunicar su nombre completo, seudónimo, domicilio, ciudad, región, código postal, país, número de teléfono, dirección de correo electrónico, etc. a los participantes en el Programa de Protección de Propiedad Intelectual de ELEX en la forma que, a nuestra discreción, entendamos necesaria o adecuada en relación con la investigación del fraude, infracción de derechos de propiedad industrial o intelectual, piratería, o cualquier otra actividad ilegal. Este derecho será ejercido por ELEX a efectos de cooperar con el cumplimiento y ejecución de la ley, independientemente que no exista una orden judicial o administrativa al efecto. -
              </p>
              <p className="mb-2">Además, ELEX se reserva el derecho de comunicar información sobre sus usuarios a otros usuarios, entidades o terceros cuando haya motivos suficientes para considerar que la actividad de un usuario sea sospechosa de intentar o cometer un delito o intentar perjudicar a otras personas. Este derecho será utilizado por ELEX a su entera discreción cuando lo considere apropiado o necesario para mantener la integridad y la seguridad de la Comunidad y la de sus usuarios, para hacer cumplir los Términos y Condiciones Generales y demás políticas del sitio y a efectos de cooperar con la ejecución y cumplimiento de la ley. Este derecho será ejercido por ELEX independientemente que no exista una orden judicial o administrativa al efecto. -
              </p>

              <h3  className="h3 mb-1 mt-5">Seguridad y medios para evitar el uso o divulgación de los Datos Personales.</h3>

              <p className="mb-2">Almacenamiento y transferencia de los Datos Personales ELEX está obligado a cumplir con toda la normativa aplicable en materia de medidas de seguridad aplicables a los Datos Personales. Adicionalmente, ELEX usa los estándares de la industria entre materia de protección de la confidencialidad de sus Datos Personales, incluyendo, en otras medidas, cortafuegos ("firewalls") y Secure Socket Layers ("SSL"). ELEX considera a los datos de sus usuarios como un activo que debe ser protegido de cualquier pérdida o acceso no autorizado. Empleamos diversas técnicas de seguridad para proteger tales datos de accesos no autorizados por usuarios de dentro o fuera de nuestra compañía. Sin embargo, es necesario tener muy en cuenta que la seguridad perfecta no existe en Internet.-
              </p>
              <p className="mb-2">El usuario de ELEX conoce y acepta expresamente que ELEX, a su exclusivo criterio, recabe, almacene y monitoree el intercambio de mensajes y correos electrónicos entre sus usuarios, efectuado dentro de la comunidad de ELEX, con el objetivo de contribuir a la seguridad de las relaciones y comunicaciones en la comunidad. -
              </p>
              <p className="mb-2">Por ello, ELEX no se hace responsable por interceptaciones ilegales o violación de sus sistemas o bases de datos por parte de personas no autorizadas. ELEX, tampoco se hace responsable por la indebida utilización de la información obtenida por esos medios. -
              </p>
              <p className="mb-2">Todos los Datos Personales de los usuarios serán almacenados en un fichero automatizado de datos personales. El fichero de Datos Personales de los usuarios de ELEX reside en los Estados Unidos Mexicanos. El usuario al inscribirse en ELEX confirma que está informado de la residencia de este fichero y autoriza esta transferencia internacional de sus datos. -
              </p>

              <h3  className="h3 mb-1 mt-5">Cambio en las preferencias de e-mails.</h3>

              <p className="mb-2">Sin perjuicio que ELEX quiere mantener a sus usuarios actualizados en todo momento sobre promociones, novedades, cambios, etc. los usuarios pueden seleccionar los e-mails e información promocional que gustarían recibir de ELEX. </p>

              <h3  className="h3 mb-1 mt-5">Procedimiento para comunicar los cambios al Aviso de Privacidad.</h3>

              <p className="mb-2">ELEX podrá modificar en cualquier momento los términos y condiciones de este Aviso de Privacidad y confidencialidad y/o las prácticas de envío de e-mails. Si decidimos introducir algún cambio material a nuestro Aviso de Privacidad, te notificaremos publicando una versión actualizada del Aviso de Privacidad en esta sección o mediante el envío de un e-mail o informándolo en la página principal u otras secciones del sitio para mantenerte actualizado de los cambios realizados. 
              </p>
              <p className="mb-2">Si hacemos cambios materiales en la forma que tus Datos Personales son administrados te notificaremos por e-mail para que puedas tomar una decisión informada respecto si aceptas o no que tus Datos Personales sean utilizados de esa forma. Si no aceptas esos términos, en ese caso quedará disuelto el vínculo contractual y tus Datos Personales no serán usados de otra forma que la que fue informada al momento de recabarse. -
              </p>
      </LayoutSmall>
    ) 
  }
}

export default withTranslation()(InfoPrivacy);
