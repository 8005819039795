import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Icon from '../../libraries/icons';
import UserPoints from './UserPoints';
import UserCoins from './UserCoins';


export default function CardVoluntary({link, url, title, points, distance, ...rest }) {
  return (
    <Link to="/voluntary_page" className={"card white py-3 px-4 overflow-hidden block " + (rest.className ? rest.className : "")}>
      <div className="flex justify-between items-center w-full mb-2">
        <img src={url} width="150px" />
        <small className="text-gray-400"><Icon className="h-4 w-4 sroke-current text-gray-300 inline-block ml-3" name="pin" /> {distance} km</small>
      </div>
      <h6 className="h6 text-yellow-900 mb-0">{title}</h6>
      <div className="flex justify-between items-center mt-4 w-full">
        <div className="flex">
          <UserCoins className="mr-4" icon="coin1" coins="300" leyend=""/>
          <UserPoints icon="impact1" points="1" leyend=""/>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4 rounded-full overflow-hidden mr-3">
            <img src="https://upload.wikimedia.org/wikipedia/commons/a/ab/Wilson_logo.jpg" />
          </div>
          <h6 className="h6 text-primary mb-0">3/20</h6>
          <Icon className="h-4 w-4 sroke-current text-gray-300 inline-block ml-3" name="heart" />
        </div>
      </div>
    </Link>
  )
}