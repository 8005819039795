import React from 'react';

import LayoutSmall from '../components/layout/LayoutSmall';
import { history } from '../routes';
import config from '../config';
import Icon from '../libraries/icons';
import Button from '../components/commons/Button';
import TextInput from '../components/forms/TextInput';
import { Form, Field } from 'react-final-form';
import ButtonIcon from '../components/commons/ButtonIcon';
import BottomNav from '../components/BottomNav';
import CardCategory from '../components/customs/CardCategory';



class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    this.from = this.props.location.state?.from || { pathname: '/' };
    this.state = {    
      categories: [
        {
          title: 'Herramientas',
          iconName: 'home'
        },
        {
          title: 'Ropa',
          iconName: 'shield'
        },
        {
          title: 'Servicios',
          iconName: 'calculator'
        },
      ],  
    }
  }
  
  componentDidMount() {
    console.log('BenefitPage');
  }

  onSubmit() {
    console.log('searching');
  }

 
  render() {
    return (
      <main className="light-primary-bg min-h-full">
        <div className="bg-primary shadow">
          <div className="container p-4">
          <Form onSubmit={this.onSubmit}>
          {({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <Field  name="search" component={TextInput} placeholder="busca lo que necesites" />
            </form>
          )}
        </Form>
          </div>
        </div>

        <section className="container-fluid p-4 ">
          <ul>
            <li className="flex justify-between items-center">
              <p className="text-gray-600" onClick={() => history.push(config.ROUTES.CATEGORY_PAGE)}>Taladro</p>
              <ButtonIcon
                className="w-5 h-5 text-gray-400"
                icon="close"
              />
            </li>
            <li className="flex justify-between items-center">
              <p className="text-gray-600">Pulidora</p>
              <ButtonIcon
                className="w-5 h-5 text-gray-400"
                icon="close"
              />
            </li>
            <li className="flex justify-between items-center">
              <p className="text-gray-600">Aspiradora</p>
              <ButtonIcon
                className="w-5 h-5 text-gray-400"
                icon="close"
              />
            </li>
          </ul>
        </section>

        <section className="p-4">
          <h4 className="text-secondary mb-4">Categoría más buscadas</h4>
          <div className="flex gap-3">
            {
              this.state.categories.map((c) => {
                return (
                  <CardCategory 
                    className="card white p-4 w-28"
                    iconName={c.iconName}
                    title={c.title}
                  />
                )
              })
            }
          </div>
        </section>
        <BottomNav/>
      </main>
    ) 
  }
}

export default SearchPage;
