import React from 'react';
import { history } from '../../routes';
import config from '../../config';
import Icon from '../../libraries/icons';
import UserPoints from './UserPoints';
import UserCoins from './UserCoins';


export default function CardProduct({ url, title, points, name, distance, ...rest }) {
  return (
    <div className={"card white overflow-hidden " + (rest.className ? rest.className : "")} onClick={() => history.push(config.ROUTES.PRODUCT_PAGE)}>
      <div className="h-48 overflow-hidden flex justify-center items-center">
        <img src={url} />
      </div>
      <div className="p-3">
        <h6 className="h6 text-secondary mb-0">{title}</h6>
        <small className="small text-gray-500">
          de <span className="text-primary">{name}</span>
        </small>
        <div className="mb-3">
          <Icon className="h-3 w-3 sroke-current text-yellow-500 inline-block m-0.5" name="star" />
          <Icon className="h-3 w-3 sroke-current text-yellow-500 inline-block m-0.5" name="star" />
          <Icon className="h-3 w-3 sroke-current text-gray-300 inline-block m-0.5" name="star" />
          <Icon className="h-3 w-3 sroke-current text-gray-300 inline-block m-0.5" name="star" />
          <Icon className="h-3 w-3 sroke-current text-gray-300 inline-block m-0.5" name="star" />
        </div>
        <div className="flex justify-between items-center">
          <div className="flex">
            <UserCoins className="mr-4" icon="coin2_4" coins="300" leyend=""/>
            <UserPoints icon="impact5" points="1" leyend=""/>
          </div>
          <small className="text-gray-500">{distance} km</small>
        </div>
      </div>
    </div>
  )
}