import React from 'react';

const CheckboxInput = ({ input, meta, label, ...rest }) => {
	return (
    <div className="form-control">
      <label className={"cursor-pointer label" + (rest.labelClassName && rest.labelClassName)}>
        <span className="label-text">{label}</span>
        <div>
          <input
            {...input}
            type="checkbox"
            className={"checkbox" }
            disabled={rest.readOnly || rest.disabled}
            id={input.name}
          />
          <span className="checkbox-mark"></span>
        </div>
      </label>
      {!rest.noError && (
        <label className="label">
          <span className="label-text-alt invalid-feedback">{meta && meta.error && meta.touched}</span>
        </label>
      )}
    </div>
  )
}
export default CheckboxInput;