import React from 'react';
import { HashRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from '../routes';
import { ToastContainer, notify } from '../libraries/notifications';


import LayoutWithSidebarMobile from '../components/layout/LayoutWithSidebarMobile';

import logo from '../assets/img/logo-negative.png';
import Sidebar from '../components/layout/Sidebar';
import Tabs from '../components/layout/Tabs';
import CardCategory from '../components/customs/CardCategory';
import CardProduct from '../components/customs/CardProduct';
import CardPost from '../components/customs/CardPost';
import CardVoluntary from '../components/customs/CardVoluntary';
import CardBenefit from '../components/customs/CardBenefit';
import CardGreen from '../components/customs/CardGreen';
import BottomNav from '../components/BottomNav';
import RowService from '../components/customs/RowService';
import CardAction from '../components/customs/CardAction';


class Home extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      posts: [
        {
          url: 'https://http2.mlstatic.com/D_NQ_NP_747546-MLA40009553712_122019-O.webp',
          title: 'Taladro Bosh',
          points: 120,
          name: 'Juan Perez',
          distance: 3
        },
        {
          url: 'https://http2.mlstatic.com/D_NQ_NP_747546-MLA40009553712_122019-O.webp',
          title: 'Podadora a motor',
          points: 140,
          name: 'Jerome Bell',
          distance: 3
        },
        {
          url: 'https://http2.mlstatic.com/D_NQ_NP_695958-MLA42146209760_062020-O.webp',
          title: 'Raqueta Pro staff',
          points: 95,
          name: 'Ronald Richards',
          distance: 3
        },
        {
          url: 'https://http2.mlstatic.com/D_NQ_NP_603746-MLA43005459805_082020-O.webp',
          title: 'Guitarra Criolla',
          points: 125,
          name: 'Darlene Robertson',
          distance: 3
        },
      ],

      products: [
        {
          url: 'https://http2.mlstatic.com/D_NQ_NP_747546-MLA40009553712_122019-O.webp',
          title: 'Taladro Bosh',
          points: 120,
          name: 'Juan Perez',
          distance: 3
        },
        {
          url: 'https://http2.mlstatic.com/D_NQ_NP_747546-MLA40009553712_122019-O.webp',
          title: 'Podadora a motor',
          points: 140,
          name: 'Jerome Bell',
          distance: 3
        },
        {
          url: 'https://http2.mlstatic.com/D_NQ_NP_695958-MLA42146209760_062020-O.webp',
          title: 'Raqueta Pro staff',
          points: 95,
          name: 'Ronald Richards',
          distance: 3
        },
        {
          url: 'https://http2.mlstatic.com/D_NQ_NP_603746-MLA43005459805_082020-O.webp',
          title: 'Guitarra Criolla',
          points: 125,
          name: 'Darlene Robertson',
          distance: 3
        },
      ],

      categories: [
        {
          title: 'Herramientas',
          iconName: 'home'
        },
        {
          title: 'Ropa',
          iconName: 'shield'
        },
        {
          title: 'Servicios',
          iconName: 'calculator'
        },
        {
          title: 'Rodados Chicos',
          iconName: 'cog'
        },
        {
          title: 'Hogar',
          iconName: 'document'
        },
        {
          title: 'Fitnes',
          iconName: 'dashboard'
        },
      ],

      volunteering: [
        {
          url: 'https://www.rosario.gob.ar/estilos/iframes/assets/img/logo.svg',
          title: 'Servir el almuerzo a los niños del comedor Sonrisas Felices',
          points: '280',
          distance: 3
        },
        {
          url: 'https://www.sociedadescomplejas.org.ar/eventos/LOMAS18/img/logo_lomas.png',
          title: 'Servir el almuerzo a los niños del comedor Sonrisas Felices',
          points: '280',
          distance: 3
        },
        {
          url: 'https://www.rosario.gob.ar/estilos/iframes/assets/img/logo.svg',
          title: 'Servir el almuerzo a los niños del comedor Sonrisas Felices',
          points: '280',
          distance: 3
        },
      ],

      donations: [
        {
          url: 'https://previews.123rf.com/images/edu1971/edu19711510/edu1971151000098/47552971-la-vivienda-de-apoyo-o-donaci%C3%B3n-de-alimentos-para-los-pobres.jpg',
          title: 'Doná alimentos no perecederos y ganá puntos ayudando.',
          points: 120,
          distance: 3
        },
        {
          url: 'https://thumbs.dreamstime.com/b/diversas-donaciones-en-una-caja-ropa-efectos-de-escritorio-y-juguetes-para-los-ni%C3%B1os-adolescentes-preparaci-n-la-escuela-aislado-153096922.jpg',
          title: 'Doná juguetes y gana puntos haciendo a un niño feliz',
          points: 90,
          distance: 3
        },
      ],

      recycling: [
        {
          url: 'https://thumbs.dreamstime.com/z/frascos-do-pl%C3%A1stico-do-lixo-27118637.jpg',
          title: 'Reciclá tus botellas de plástico y ganá puntos',
          points: 120,
          distance: 3
        },
        {
          url: 'https://previews.123rf.com/images/coxon/coxon0702/coxon070200014/763874-centro-de-reciclaje-con-paletas-de-cart%C3%B3n-reciclado-.jpg',
          title: 'Reciclá tus cartones y ganá puntos',
          points: 90,
          distance: 3
        },
      ],

      benefits: [
        {
          url: 'https://tentulogo.com/wp-content/uploads/2017/07/mcdonalds-logo.jpg',
          title: '1 combo bigmac',
          points: 120,
        },
        {
          url: 'https://static.nike.com/a/images/f_jpg,q_auto:eco/61b4738b-e1e1-4786-8f6c-26aa0008e80b/swoosh-logo-black.png',
          title: '30% en todas las tiendas',
          points: 90,
        },
        {
          url: 'https://tentulogo.com/wp-content/uploads/2017/07/mcdonalds-logo.jpg',
          title: '2x1 en Papas',
          points: 120,
        },
      ],
    }

  }
  
  componentDidMount() {
    console.log('HOME PAGE');
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    return (
      <LayoutWithSidebarMobile
        main={{ className: "text-content-400 home red"}}
        header={{ 
          logo: true,
        }}
        // container={{ className: "px-8"}}
      >      
      {/* <main className="light-primary-bg light-primary-text pb-10">
        <Sidebar 
          header={{ 
            logo: true,
            // left: { icon: 'arrow_left', action: () => history.go(-1) }
          }}
        /> */}
        <ToastContainer/>
        <div>
          <HashRouter>
            <Tabs items={[
                //////////////// Muro ////////////////
                { 
                  name: this.t("Muro"),
                  icon:'home',
                  route: 'feed',
                  render: () => (
                    <div className="tab-content p-4">
                       <div className="grid gap-4">
                        {
                          this.state.posts.map((po, index) => {
                            return (
                              <CardPost 
                                key={'img' + index}
                                url={po.url}
                                title={po.title}
                                points={po.points}
                                name={po.name}
                                distance={po.distance}
                              />
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                },
                //////////////// Ofertas ////////////////
                {
                  name: this.t("Ofertas"),
                  route: 'offer',
                  icon:'home',
                  render: () => (
                    <div className="tab-content">
                      <section className="p-4">
                        <h4 className="h4 text-secondary mb-3"> Productos que te pueden interesar</h4>
                        <div className="grid grid-cols-2 gap-4">
                          {
                            this.state.products.map((p, index) => {
                              return (
                                <CardProduct 
                                  key={'img' + index}
                                  url={p.url}
                                  title={p.title}
                                  points={p.points}
                                  name={p.name}
                                  distance={p.distance}
                                />
                              )
                            })
                          }
                        </div>
                      </section>
                      <section className="py-10">
                        <h4 className="h4 text-secondary px-4 mb-3"> Servicios</h4>
                        <div className="container-fluid bg-white shadow">
                          {
                            this.state.products.map((p, index) => {
                              return (
                                <RowService 
                                  key={'img' + index}
                                  url={p.url}
                                  title={p.title}
                                  points={p.points}
                                  name={p.name}
                                  distance={p.distance}
                                />
                              )
                            })
                          }
                        </div>
                      </section>
                      <section className="py-10">
                        <h4 className="h4 text-secondary px-4 mb-3"> Favores</h4>
                        <div className="container-fluid bg-white shadow">
                          {
                            this.state.products.map((p, index) => {
                              return (
                                <RowService 
                                  key={'img' + index}
                                  url={p.url}
                                  title={p.title}
                                  points={p.points}
                                  name={p.name}
                                  distance={p.distance}
                                />
                              )
                            })
                          }
                        </div>
                      </section>
                      <section className="p-4 py-10">
                        <h4 className="h4 text-secondary mb-0"> Prestá y Ganá Puntos </h4>
                        <p className="text-primary">Lo que la comunidad busca y no encuentra </p>
                        <div className="grid grid-cols-3 gap-4 mt-2">
                          {
                            this.state.categories.map((c) => {
                              return (
                                <CardCategory 
                                  className="card white p-4 col-span-1"
                                  iconName={c.iconName}
                                  title={c.title}
                                />
                              )
                            })
                          }
                        </div>
                        <a className="text-right block p-2 text-primary" href="" >Ver todos</a>
                      </section>
                    </div>
                  )
                },
                //////////////// Servicio Comunitario ////////////////
                {
                  name: this.t("Servicio comunitario"),
                  route: 'voluntaries',
                  icon:'home',
                  render: () => (
                    <div className="tab-content">
                      <section className="p-4">
                        <div className="flex justify-between items-center mb-2">
                          <h4 className="h4 text-secondary"> Voluntariado </h4>
                          <a href="" className="text-teal-600" >Ver todos</a>
                        </div>
                          {
                            this.state.volunteering.map((v, index) => {
                              return (
                                <CardVoluntary
                                  className="mb-3"
                                  key={'img' + index}
                                  url={v.url}
                                  title={v.title}
                                  points={v.points}
                                  distance={v.distance}
                                />
                              )
                            })
                          }

                      </section>
                      <section className="p-4 py-10">
                        <div className="flex justify-between items-center">
                          <h4 className="h4 text-secondary mb-0"> Donaciones </h4>
                          <a href=""  className="text-primary">Ver todos</a>
                        </div>
                        <div className="grid grid-cols-2 gap-4 mt-2">
                          {
                            this.state.donations.map((d, index) => {
                              return (
                                <CardGreen 
                                  key={'img' + index}
                                  url={d.url}
                                  title={d.title}
                                  points={d.points}
                                  distance={d.distance}
                                  buttonName="Donar"
                                />
                              )
                            })
                          }
                        </div>
                      </section>

                      <section className="p-4 py-10">
                        <div className="flex justify-between items-center">
                          <h4 className="h4 text-secondary mb-0"> Reciclados </h4>
                          <a href="" className="text-primary">Ver todos</a>
                        </div>
                        <div className="grid grid-cols-2 gap-4 mt-2">
                          {
                            this.state.recycling.map((r, index) => {
                              return (
                                <CardGreen 
                                  key={'img' + index}
                                  url={r.url}
                                  title={r.title}
                                  points={r.points}
                                  distance={r.distance}
                                  buttonName="Reciclar"
                                />
                              )
                            })
                          }
                        </div>
                      </section>
                    </div>
                  )
                },
                //////////////// Segui Sumando ////////////////
                { 
                  name: this.t("Segui Sumando"),
                  route: 'actions',
                  icon:'home',
                  render: () => (
                    <div className="tab-content p-4">
                      <div className="scrolling-wrapper mb-4">
                        <h4 className="h4 text-secondary mr-4"> Transporte </h4>
                        <h4 className="h4 text-gray-400 mr-4"> Comida </h4>
                        <h4 className="h4 text-gray-400 mr-4"> Casa </h4>
                        <h4 className="h4 text-gray-400 mr-4"> Energía </h4>
                        <h4 className="h4 text-gray-400 mr-4 min-w-max"> Aire libre </h4>
                        <h4 className="h4 text-gray-400 mr-4"> Familia </h4>
                        <h4 className="h4 text-gray-400 mr-4"> Trabajo </h4>
                      </div>
                      <div className="grid gap-4">
                        {
                          this.state.posts.map((po, index) => {
                            return (
                              <CardAction 
                                key={'img' + index}
                                url={po.url}
                                title={po.title}
                                points={po.points}
                                name={po.name}
                                distance={po.distance}
                              />
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                },
                //////////////// Beneficios ////////////////
                { 
                  name: this.t("Beneficios"),
                  route: 'benefits',
                  icon:'home',
                  render: () => (
                    <div className="tab-content p-4">
                      <section className="">
                        <div className="flex justify-between items-center mb-2">
                          <h4 className="h4 text-secondary mb-0"> Canjeá tus puntos </h4>
                          <a href="" className="text-teal-600">Ver todos</a>
                        </div>
                          {
                            this.state.benefits.map((b, index) => {
                              return (
                                <CardBenefit 
                                  className="mb-3"
                                  key={'img' + index}
                                  url={b.url}
                                  title={b.title}
                                  points={b.points}
                                />
                              )
                            })
                          }
                      </section>
                    </div>
                  )
                },
                //////////////// Ranking ////////////////
                { 
                  name: this.t("Ranking"),
                  route: 'ranking',
                  icon:'home',
                  render: () => (
                    <div className="tab-content p-4">
                       <div className="card white">
                         <ul>
                           <li className="flex items-center py-2 px-4 border-b border-gray-100">
                              <p className="text-gray-300 text-2xl mr-2">1</p>
                              <div className="w-10 h-10 mr-2 overflow-hidden flex justify-center items-center rounded-full">
                                <img src="https://thumbs.dreamstime.com/b/diversas-donaciones-en-una-caja-ropa-efectos-de-escritorio-y-juguetes-para-los-ni%C3%B1os-adolescentes-preparaci-n-la-escuela-aislado-153096922.jpg" />
                              </div>
                              <h6 className="h6 text-secondary mb-0 mr-auto"> Nombre </h6>
                              <h4 className="h4 text-gray-500 mb-0"> 11545 </h4>
                           </li>
                           <li className="flex items-center py-2 px-4 border-b border-gray-100">
                              <p className="text-gray-300 text-2xl mr-2">2</p>
                              <div className="w-10 h-10 mr-2 overflow-hidden flex justify-center items-center rounded-full">
                                <img src="https://thumbs.dreamstime.com/b/diversas-donaciones-en-una-caja-ropa-efectos-de-escritorio-y-juguetes-para-los-ni%C3%B1os-adolescentes-preparaci-n-la-escuela-aislado-153096922.jpg" />
                              </div>
                              <h6 className="h6 text-secondary mb-0 mr-auto"> Nombre </h6>
                              <h4 className="h4 text-gray-500 mb-0"> 11545 </h4>
                           </li>
                           <li className="flex items-center py-2 px-4 border-b border-gray-100">
                              <p className="text-gray-300 text-2xl mr-2">3</p>
                              <div className="w-10 h-10 mr-2 overflow-hidden flex justify-center items-center rounded-full">
                                <img src="https://thumbs.dreamstime.com/b/diversas-donaciones-en-una-caja-ropa-efectos-de-escritorio-y-juguetes-para-los-ni%C3%B1os-adolescentes-preparaci-n-la-escuela-aislado-153096922.jpg" />
                              </div>
                              <h6 className="h6 text-secondary mb-0 mr-auto"> Nombre </h6>
                              <h4 className="h4 text-gray-500 mb-0"> 11545 </h4>
                           </li>
                           <li className="flex items-center py-2 px-4 border-b border-gray-100">
                              <p className="text-gray-300 text-2xl mr-2">4</p>
                              <div className="w-10 h-10 mr-2 overflow-hidden flex justify-center items-center rounded-full">
                                <img src="https://thumbs.dreamstime.com/b/diversas-donaciones-en-una-caja-ropa-efectos-de-escritorio-y-juguetes-para-los-ni%C3%B1os-adolescentes-preparaci-n-la-escuela-aislado-153096922.jpg" />
                              </div>
                              <h6 className="h6 text-secondary mb-0 mr-auto"> Nombre </h6>
                              <h4 className="h4 text-gray-500 mb-0"> 11545 </h4>
                           </li>
                           <li className="flex items-center py-2 px-4 border-b border-gray-100">
                              <p className="text-gray-300 text-2xl mr-2">5</p>
                              <div className="w-10 h-10 mr-2 overflow-hidden flex justify-center items-center rounded-full">
                                <img src="https://thumbs.dreamstime.com/b/diversas-donaciones-en-una-caja-ropa-efectos-de-escritorio-y-juguetes-para-los-ni%C3%B1os-adolescentes-preparaci-n-la-escuela-aislado-153096922.jpg" />
                              </div>
                              <h6 className="h6 text-secondary mb-0 mr-auto"> Nombre </h6>
                              <h4 className="h4 text-gray-500 mb-0"> 11545 </h4>
                           </li>
                         </ul>
                       </div>
                    </div>
                  )
                },
              ]} />
          </HashRouter>
        </div>


        {/* <section className="bg-white p-3 shadow-lg">
          <div className="flex justify-between items-center">
            <h4 className="h4 text-secondary mb-0"> ¿Qué Necesitás?</h4>
            <a href="" className="text-gray-400" >Ver todos</a>
          </div>
          <div className="scrolling-wrapper pt-8 pb-4">
            {
              this.state.categories.map((c) => {
                return (
                  <CardCategory 
                    className="card"
                    iconName={c.iconName}
                    title={c.title}
                  />
                )
              })
            }
          </div>
        </section> */}
        {/* <section className="p-4 my-4">
          <div className="grid grid-cols-6 gap-4"> 
            <div className="card white overflow-hidden">
              <img src="https://upload.wikimedia.org/wikipedia/commons/a/ab/Wilson_logo.jpg" />
            </div>
            <div className="card white overflow-hidden">
              <img src="https://upload.wikimedia.org/wikipedia/commons/a/ab/Wilson_logo.jpg" />
            </div>
            <div className="card white overflow-hidden">
              <img src="https://upload.wikimedia.org/wikipedia/commons/a/ab/Wilson_logo.jpg" />
            </div>
            <div className="card white overflow-hidden">
              <img src="https://upload.wikimedia.org/wikipedia/commons/a/ab/Wilson_logo.jpg" />
            </div>
            <div className="card white overflow-hidden">
              <img src="https://upload.wikimedia.org/wikipedia/commons/a/ab/Wilson_logo.jpg" />
            </div>
            <div className="card white overflow-hidden">
              <img src="https://upload.wikimedia.org/wikipedia/commons/a/ab/Wilson_logo.jpg" />
            </div>
          </div>
        </section> */}
       
        
        

        

        
        <BottomNav/>
      </LayoutWithSidebarMobile>
    ) 
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Home));
