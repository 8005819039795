
import React from 'react';

import Sidebar from '../components/layout/Sidebar';


class Returns extends React.Component {
  constructor(props) {
    super(props);
    this.from = this.props.location.state?.from || { pathname: '/' };
    this.state = {
    }
  }
  
  componentDidMount() {
    console.log('Favourite');
  }

 
  render() {
    return (

      <main className="light-primary-bg">
        <Sidebar 
          header={{ 
            title: "Devoluciones",
          }}
        />

      </main>
    ) 
  }
}

export default Returns;
