import React from 'react';
import Button from '../commons/Button';
import { history } from '../../routes';
import config from '../../config';
import UserPoints from './UserPoints';
import UserCoins from './UserCoins';


export default function CardGreen({url, title, points, distance, buttonName, ...rest }) {
  return (
    <div className={"card white overflow-hidden " + (rest.className ? rest.className : "")}>
      <div className="h-40 overflow-hidden flex justify-center items-center">
        <img src={url} />
      </div>
      <div className="p-3 w-full">
        <h6 className="h6 text-secondary mb-4">{title}</h6>
        <div className="flex">
          <UserCoins className="mr-4" icon="coin1" coins="300" leyend=""/>
          <UserPoints icon="impact1" points="1" leyend=""/>
        </div>
        <Button
          className="btn btn-sm btn-block btn-primary capitalize mt-2"
          title={buttonName}
          onClick={() => history.push(config.ROUTES.GREEN_PAGE)}
        />
      </div>
    </div>
  )
}