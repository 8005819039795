import React from 'react';
import { history } from '../../routes';
import config from '../../config';
import UserPoints from './UserPoints';
import UserCoins from './UserCoins';


export default function CardAction({ url, title, points, name, distance, ...rest }) {
  return (
    <div className={"card white overflow-hidden " + (rest.className ? rest.className : "")} onClick={() => history.push(config.ROUTES.PRODUCT_PAGE)}>
      <div className="h-48 overflow-hidden flex justify-center items-center">
        <img src={url} />
      </div>
      <div className="p-3">
        <h6 className="h6 text-secondary mb-0">{title}</h6>
        <div className="flex justify-between items-center mt-3">
          <div className="flex">
            <UserCoins className="mr-4" icon="coin1" coins="300" leyend=""/>
            <UserPoints icon="impact1" points="1" leyend=""/>
          </div>
          <small className="text-gray-500">{distance} km</small>
        </div>
      </div>
      
    </div>
  )
}