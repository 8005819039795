import React from 'react';

import LayoutSmall from '../components/layout/LayoutSmall';
import { history } from '../routes';
import config from '../config';
import ListProduct from '../components/customs/RowProduct';



class CategoryPage extends React.Component {
  constructor(props) {
    super(props);
    this.from = this.props.location.state?.from || { pathname: '/' };
    this.state = {    
      products: [
        {
          url: 'https://http2.mlstatic.com/D_NQ_NP_747546-MLA40009553712_122019-O.webp',
          title: 'Taladro Bosh',
          points: 120,
          name: 'Juan Perez',
          distance: 3
        },
        {
          url: 'https://http2.mlstatic.com/D_NQ_NP_747546-MLA40009553712_122019-O.webp',
          title: 'Podadora a motor',
          points: 140,
          name: 'Jerome Bell',
          distance: 3
        },
        {
          url: 'https://http2.mlstatic.com/D_NQ_NP_695958-MLA42146209760_062020-O.webp',
          title: 'Raqueta Pro staff',
          points: 95,
          name: 'Ronald Richards',
          distance: 3
        },
        {
          url: 'https://http2.mlstatic.com/D_NQ_NP_603746-MLA43005459805_082020-O.webp',
          title: 'Guitarra Criolla',
          points: 125,
          name: 'Darlene Robertson',
          distance: 3
        },
      ],
    }
  }
  
  componentDidMount() {
    console.log('BenefitPage');
  }

  onSubmit() {
    console.log('searching');
  }

 
  render() {
    return (
      <LayoutSmall
        main={{ className: "light-primary-bg text-primary w-full min-h-full"}}
        header={{ 
          title: "Category name",
          className: "bg-primary flex justify-between p-3",
          left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.HOME) },
        }}
        container={{ className: "px-0"}}
      > 
          <section className="container-fluid bg-white shadow mt-10">
            {
              this.state.products.map((p, index) => {
                return (
                  <ListProduct 
                    key={'img' + index}
                    url={p.url}
                    title={p.title}
                    points={p.points}
                    name={p.name}
                    distance={p.distance}
                  />
                )
              })
            }
          </section>
      </LayoutSmall>
      
    ) 
  }
}

export default CategoryPage;
