import React from 'react';

import LayoutSmall from '../components/layout/LayoutSmall';
import { history } from '../routes';
import config from '../config';


class QrPage extends React.Component {
  constructor(props) {
    super(props);
    this.from = this.props.location.state?.from || { pathname: '/' };
    this.state = {      
    }
  }
  
  componentDidMount() {
    console.log('BenefitPage');
  }

 
  render() {
    return (
      <LayoutSmall
        main={{ className: "light-primary-bg text-primary w-full h-full"}}
        header={{ 
          className: "text-primary flex justify-between p-3 fixed",
          left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.BENEFIT_PAGE) }
        }}
        container={{ className: "px-0"}}
      > 
        <div className="bg-white shadow-lg">
          <h1 className="h1 text-secondary p-4 text-center">1 Combo bigMac</h1>
          <div className="container p-4">
            <div>
              <img src="https://esmeraldadiazaroca.com/wp-content/uploads/2011/03/auge-codigos-qr.jpg" className="w-full my-3" alt="" />
            </div>
            <div className="p-4 border-t border-gray-100 text-center">
              <p className="uppercase text-gray-400">Código</p>
              <h4 className="h3 text-gray-600">743782</h4>
            </div>
          </div>
        </div>

        <section className="container-fluid p-4">
          <h4 className="text-secondary mb-4">Como canjearlo</h4>
          <p className="text-gray-600">El día Domingo 27 de Septiembre se requiere la ayuda de 20 personas para ayudarnos a servir los platos con comida a chicos de 6 a 12 años que almuerzan de forma recurrente todos los días en el Comedor "Sonrisas Felices".

            Dirección: Av Dardo Rocha 2023, Rosario.
            Fecha: 27 de Septiembre
            Horario: 12 a 14 pm
            Requisito: Llevar barbijo propio. 
            (nosotros les daremos los guantes de latex)</p>
        </section>

      </LayoutSmall>
    ) 
  }
}

export default QrPage;
