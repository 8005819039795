import React from 'react';
import { withTranslation } from 'react-i18next';
import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';


class InfoTerms extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
    }
  }
  
  componentDidMount() {
    console.log('TERMS PAGE');
  }

  onExit = async values => {
    history.push(config.ROUTES.HOME)        
  }
 
  render() {
    return (
      <LayoutSmall
        header={{ 
          className: "bg-primary",
          title: this.t("Terms and conditions"),
          left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.HOME) }
        }}      
        main={{ 
          className: "light-primary-bg text-gray-700",
        }}
      >
        <p className="mb-2">
        Los presentes términos y condiciones generales de uso, serán aplicables al uso de la aplicación móvil denominada ELEX (la “Aplicación”), del sitio de internet www.lavadoelex.com (el “Sitio”), así como de todos los contenidos, información, servicios y funciones disponibles en o a través de los mismos (los “Contenidos”).-
Por el simple hecho de acceder al Sitio, instalar la Aplicación o hacer uso de los Contenidos y Servicios (según se define más adelante) disponibles en los mismos, usted acepta y se obliga frente a la "empresa", (en adelante “ELEX”) conforme a los presentes Términos y Condiciones, adquiriendo la condición de usuario. Si usted no desea sujetarse a lo dispuesto en estos Términos y Condiciones, deberá abandonar el Sitio, desinstalar la Aplicación y abstenerse de hacer uso de los Contenidos y Servicios.-
        </p>
        <p className="mb-2">
        Todos los Contenidos que ELEX pone al alcance del Usuario a través de la Aplicación y/o el Sitio contiene elementos amparados por normas de propiedad intelectual, incluyendo protección del software, marcas, fotografías, logos, diseños, imágenes gráficas, música y sonido, sin que esta descripción implique limitación de naturaleza alguna. Los Servicios, así como los Contenidos sólo podrán ser utilizados por personas físicas mayores de edad, o por personas morales o jurídicas, por lo que al hacer uso del Sitio o la Aplicación, el Usuario manifiesta, (i) en caso de ser persona física, ser mayor de edad y contar con la capacidad suficiente para obligarse conforme a los presentes Términos y Condiciones; o (ii) en caso de representar a una persona moral o jurídica, contar con las facultades suficientes para obligarla conforme al presente documento.
        </p>

        <h3 className="h3 mb-1 mt-5">Servicios.</h3>
        <p className="mb-2">
A traves de la Aplicación y del Sitio, ELEX ofrece a sus Usuarios una plataforma en línea, por medio de la cual pueden solicitar información, así como contratar servicio de lavado de autos por ubicación o a domicilio prestados por terceros (los “Servicios”) proveedores de lavado (los “LEXERS”). Los LEXERS son terceros independientes de ELEX, con recursos humanos y materiales propios, limitándose la participación de LEXERS a una mera intermediación entre el oferente y demandante de los mismos mediante una plataforma digital.
        </p>

        <h3 className="h3 mb-1 mt-5">Cuenta del Usuario.</h3>
        <p className="mb-2">
        
Para poder hacer uso de cualquiera de los servicios de “ELEX” ya sean en cualquiera de sus plataformas móviles (IOS y Android) o en el Sitio, el Usuario deberá registrarse mediante la creación de una cuenta, para lo cual deberá ingresar la información que sea requerida para crear la cuenta, tales como datos de identificación, número telefónico, datos  
de tarjeta de crédito o pago en efectivo, datos de facturación, datos de su(s) vehículos, entre otros, y creará una contraseña personal (password) la cual le servirá para ingresar en cualquier momento a dicha cuenta, pudiendo ser a través de su cuenta de correo personal, Facebook o cuenta de correo de Gmail.-
El Usuario reconoce que la información proporcionada sobre sí mismo es verdadera, correcta, actual y completa, responsabilizándose civil y penalmente de dicha información, así como manifiesta haber leído, comprendido y aceptado el Aviso de Privacidad que se encuentra a su disposición en la Aplicación y en el Sitio. En caso dado de que la información del Usuario al momento de crear su cuenta resultare falsa, equivocada o incompleta, de tal modo que resulte imposible la comprobación e identificación del Usuario, ELEX tendrá el derecho de suspender o cancelar inmediatamente la cuenta del Usuario, sin necesidad de previo aviso, responsabilizándose en todo momento el Usuario por los daños finalmente ocasionados.-

El Usuario podrá acceder y modificar o actualizar la información de su cuenta en cualquier momento.-

El Usuario es responsable de la protección de la confidencialidad de la contraseña de su cuenta en la Aplicación y en el Sitio. El Usuario autoriza expresamente a ELEX a mantener en su registro las informaciones proporcionadas por el Usuario, también autoriza a ELEX a proporcionar información sobre el referido registro a (i)los LEXERS, únicamente para la prestación de los mismos, y (ii) a sus asociados estratégicos, comerciales o técnicos con la finalidad de ofrecer mejores Servicios y/o Contenidos del Usuario, de conformidad con lo dispuesto en el Aviso de Privacidad. Además, el Usuario permite expresamente a ELEX recopilar información para la realización de seguimiento de tráfico, con intención de identificar grupos y perfiles de usuarios, así como para fines de orientación publicitaria. El Usuario será responsable por todas las operaciones efectuadas en su cuenta, pues el acceso a la misma está restringido al ingreso y uso de su contraseña (password) personal, de conocimiento exclusivo del Usuario.-

En caso de robo o extravío de la contraseña personal del Usuario, éste se obliga a notificar dicha situación a ELEX por medio de su área de atención a usuarios de manera inmediata, en el entendido de que el usuario original de la cuenta será el único responsable de los daños y perjuicios que pudieran ser ocasionados por el uso indebido de terceros.-

El Usuario podrá cancelar su cuenta o desinstalar la Aplicación en cualquier momento, en el entendido que ELEX no será responsable de cualquier pérdida de información que pudiera sufrir durante dicho proceso. Asimismo, la cancelación de la cuenta o la desinstalación de la Aplicación no libera al Usuario de cualquier obligación o responsabilidad adquirida por éste con anterioridad.-

Asimismo, el Usuario reconoce que ELEX podrá en cualquier momento y sin necesidad de aviso previo, suspender o cancelar la cuenta del Usuario, en caso de que a criterio de ELEX, el Usuario haga uso de la misma en contravención a cualquier disposición de los presentes Términos y Condiciones, por lo que el Usuario libera a ELEX de cualquier responsabilidad derivada de dicha suspensión o cancelación.-
        </p>

        <h3 className="h3 mb-1 mt-5">Uso de los Servicios.</h3>
        <p className="mb-2">
        
Una vez registrado, el usuario podrá acceder por medio de su cuenta a los diversos contenidos y servicios ofrecidos a través de la aplicación móvil: “ELEX” disponible para IOS y Android o desde el Sitio Web: “www.lavadoelex.com”. Para efecto de lo cual, el usuario deberá proporcionar la información adicional que se le requiera para asegurar la calidad del servicio por parte de ELEX, la cual podrá incluir, pero no limitarse a: su localización o la de los bienes a los que pretende realizar el servicio de eco-limpieza o eco-lavado mediante la plataforma así como el domicilio en el cual desea que se realice, la forma de pago de su preferencia y los datos correspondientes al vehículo a limpiar o eco-lavar, entre otros.-

Queda estrictamente prohibido utilizar los servicios de eco-limpieza o eco-lavado de ELEX en vehículos que puedan contener sustancias ilícitas, que hayan sido objeto de un ilícito, que contengan estupefacientes y sustancias ilegales por parte de los usuarios, en tal caso el cliente será totalmente responsable de las implicaciones en nuestros servicios, desligando a ELEX de cualquier responsabilidad legal.-

Una vez ingresada la información requerida al usuario, la Aplicación o el Sitio Web generarán una orden de servicio en nombre del Usuario (la “Orden de Servicio”), la cual será enviada al LEXER pertinente, el cual podrá aceptar o rechazar dicha Orden de Servicio.-

Cada Orden de Servicio se considerará como una oferta económica por parte del Usuario respecto del servicio de lavado solicitado, en virtud de lo cual, se entenderá condicionada a la aceptación por parte del LEXER, la cual no se tendrá por otorgada sino hasta el momento en que ELEX confirme definitivamente la operación al Usuario a través de una comunicación electrónica por medio de las aplicaciones móviles (IOS o Android) o del Sitio Web. En el supuesto de que el ELEX rechace la Orden de Servicio, ésta podrá ser reenviada a otro(s) LEXER(s), o rechazada definitivamente, en cuyo caso le será notificada dicha situación al Usuario.-

En caso de que el LEXER acepta la Orden de Servicio, le será notificado al Usuario, la Tarifa estimada de pago e información adicional que resulte pertinente para la correcta prestación de dichos servicios por parte de los LEXERS s, en todo momento el usuario podrá comunicarse con su LEXER para dar nuevas instrucciones o seguimiento de su servicio.-

A la aceptación de la Orden de Servicio por parte del LEXER, éste y el Usuario se entenderán obligados por los términos contenidos en el modelo de contrato de servicios que los LEXERS que aceptan Órdenes de Servicio a través de la Aplicación y del Sitio Web han convenido en usar a efecto de otorgar a los Usuarios un trato similar.-

A efecto de que el Usuario esté en posibilidades de recibir los servicios de eco-limpieza o eco-lavado, ELEX podrá reenviar la Orden de Servicio a cuántos LEXERS considere pertinentes a efecto de que la misma pueda ser aceptada por alguno de ellos, en el entendido que ELEX no garantiza al Usuario que dicha Orden de Servicios sea aceptada por alguno de ellos, toda vez que la aceptación o rechazo de dichas Órdenes de Servicio será a discreción de los LEXERS.-

Los contenidos y plataformas brindadas por ELEX así como el uso de la Aplicación y del Sitio no tendrán costo para el Usuario, sin embargo, ELEX se reserva el derecho a establecer un costo por dicho uso en cualquier momento, mediante simple aviso al Usuario por medio de la Aplicación o del Sitio.-

Sin perjuicio de lo anterior, las tarifas o precios por los servicios de eco-limpieza o
eco-lavado (las “Tarifas”), se indican directamente dentro de la aplicación (IOS o Android) y dependen del tipo de vehículo del que se trate con respecto al tamaño y también al tipo de servicio solicitado que puede ser servicio exterior ó servicio completo. De igual forma el Usuario podrá solicitar servicios extra los cuales tendrán “Tarifas” adicionales y también se informan debidamente dentro de la aplicación.-

Las Tarifas podrán ser modificadas en cualquier momento, por lo que será responsabilidad del Usuario verificar las Tarifas vigentes previo al envío de cualquier Orden de Servicio
        </p>

        <h3 className="h3 mb-1 mt-5">Pagos y facturación.</h3>
        <p className="mb-2">
        
El Usuario reconoce y acepta realizar el pago de los servicios de lavado a ELEX, quien los recibirá por cuenta y en nombre del LEXER, por lo que una vez aceptada una Orden de Servicio por parte de algún LEXER, el Usuario autoriza a “KONECTA” a realizar el cobro de la Tarifa correspondiente, de la tarjeta de crédito proporcionada por el Usuario, o mediante cualquier otra forma de pago admitida por las aplicaciones móviles o Sitio Web.-

Una vez confirmado el cobro exitoso por parte de “KONECTA”, el Usuario podrá solicitar a través de su cuenta en las aplicaciones móviles o en el Sitio Web la factura correspondiente y fiscalmente requisitada a los servicios de lavado prestados, misma que será enviada por el LEXER la cuenta de correo electrónico que haya sido proporcionada por el Usuario, a más tardar dentro de los cinco días hábiles siguientes a la fecha en que la haya solicitado. Será responsabilidad del Usuario verificar que los datos de facturación así como la cuenta de correo electrónico proporcionados sean correctos previo a la solicitud de la factura, toda vez que ELEX no se responsabiliza por facturas emitidas con datos erróneos o enviadas a correos electrónicos incorrectos, por causas imputables al Usuario, en el entendido que por ningún motivo se reexpedirán, reenviarán o corregirán facturas cuando éstas hayan sido emitidas o enviadas erróneamente por causas imputables al Usuario.-

De igual manera, será responsabilidad del Usuario contar con fondos suficientes para el pago de la Tarifa en la tarjeta de crédito proporcionada o en el medio de pago acordado, por lo que en caso de que no sea posible realizar el cobro de la Tarifa correspondiente, la Orden de Servicio podrá ser cancelada de manera inmediata o podrá ser negado el servicio en la siguiente solicitud por parte del Usuario en tanto no sea liquidado el servicio previo, siendo responsable el Usuario por cualquier daño o perjuicio ocasionado al LEXER y/o a ELEX por dicha cancelación.-

El Usuario reconoce que ELEX únicamente presta servicios de intermediación y recepción de pagos por cuenta de los LEXERS, por lo que cualquier aclaración o reclamación relacionada con dichos pagos, siempre que no tenga que ver con la plataforma o el procesador de pagos, deberá ser presentada directamente al LEXER.-

        </p>

        <h3 className="h3 mb-1 mt-5"> Zona de Cobertura.</h3>
        <p className="mb-2">
       
Los servicios de lavado únicamente estarán disponibles en las zonas geográficas marcadas dentro de la Aplicación y el Sitio (las “Ver Coberturas”), en el entendido que los LEXERS únicamente podrán prestar dichos servicios siempre y cuando la ubicación del vehículo se encuentre dentro de las zonas de cobertura indicadas y dentro de los horarios también indicados, siempre que el tipo de servicio lo permita.-
ELEX se reserva el derecho de modificar las Zonas de Cobertura y horarios de atención o servicio en cualquier momento, sin necesidad de aviso previo y mediante su simple actualización en la Aplicación o en el Sitio.-

Por lo anterior, el Usuario deberá verificar que el servicio que pretende solicitar se encuentra dentro de alguna Zona de Cobertura previo a su solicitud y dentro del horario establecido.-

En cualquier caso la Zona de Cobertura se limitará a áreas urbanas y dentro de horarios adecuados para que el LEXER brinde el servicio correctamente.-

        </p>

        <h3 className="h3 mb-1 mt-5">Servicios.</h3>
        <p className="mb-2">
        
El Usuario únicamente podrá hacer uso de los servicios de lavado que sean permitidos por las leyes aplicables, así como de los expresamente señalados en la Aplicación o en el Sitio, por lo que el Usuario será el único responsable de la veracidad de la información proporcionada a ELEX en la Orden de Servicio, así como que, el vehículo esté en las condiciones adecuadas y lícitas. En ningún caso el vehículo podrá encontrarse en algún sitio que no permita brindar el servicio de manera adecuada por parte del LEXER así como, dentro de un lugar en donde el LEXER no tenga restricción alguna a fin de cumplir con el servicio solicitado, caso contrario el Usuario deberá asegurarse de que cuente con las autorizaciones requeridas para que el LEXER pueda prestar el servicio solicitado.-
 De igual manera, será responsabilidad del Usuario ubicar al LEXER debidamente donde se encuentra el vehículo al cual se le brindará el servicio de eco-limpieza o eco-lavado, de tal manera que el LOOEPR pueda cumplir de forma correcta con el servicio solicitado. El LEXER podrá negarse a realizar servicios en vehículos que no reúnan las características anteriores o que a su juicio requieran de un manejo especializado o de intervención por parte de las autoridades, en cuyo caso no habrá lugar a reembolso alguno al Usuario.-
 ELEX no se hace responsable por daños en vehículos que no se encuentren en las condiciones y/o ubicaciones adecuadas y correctas para poder brindar el servicio solicitado por el Usuario.-

Asimismo, el Usuario deberá hacer del conocimiento del LEXER todos los valores dejados en el interior de su auto sobre todo si se trata de aparatos electrónicos o de alto valor, y preferentemente se sugiere no dejar objetos de valor cuando el tipo de servicio solicitado sea por dentro y por fuera del vehículo.-

Los LEXERS podrán, más no tendrán la obligación de, inspeccionar los vehículos en cualquier momento, así como permitir a las autoridades competentes que así lo requieran, a llevar a cabo las inspecciones que consideren pertinentes. Asimismo, ELEX y/o los LEXERS se reservan el derecho a notificar a las autoridades correspondientes en caso de detectar algún incumplimiento a lo dispuesto en el presente apartado, que a su juicio pudiera constituir una violación a la legislación aplicable o a derechos de terceros.
        </p>

        <h3 className="h3 mb-1 mt-5">Contenidos.</h3>
        <p className="mb-2">
        
Los Contenidos mostrados en cualquiera de las aplicaciones móviles o en el Sitio Web podrán ser propios o de terceros, y podrán incluir todo tipo de archivos tales como textos, imágenes, sonidos y videos, así como enlaces (links) a otros sitios o aplicaciones ajenos a ELEX.-

El acceso a cualquier Contenido incluyendo los enlaces mostrados en las aplicaciones móviles o en el Sitio son opcionales, y serán bajo el riesgo y responsabilidad exclusiva del Usuario, quien reconoce que tiene en todo momento absoluta discrecionalidad de activar o no dichos enlaces.-

Por lo anterior y toda vez que ELEX no tiene control sobre los Contenidos de terceros, incluidos los sitios o aplicaciones a que lleven los enlaces, ELEX no será responsable por los contenidos, materiales, acciones y/o servicios prestados por los mismos, ni por daños o pérdidas ocasionadas por la utilización de los mismos, sean causadas directa o indirectamente. La presencia de enlaces a otros sitios web no implica una sociedad, relación, aprobación, patrocinio o respaldo de ELEX a dichos sitios y sus contenidos

        </p>

        <h3 className="h3 mb-1 mt-5">Acceso y uso de la Aplicación y del Sitio.</h3>
        <p className="mb-2">
        
Se encuentra estrictamente prohibido al Usuario violar o intentar violar la seguridad de las aplicaciones móviles o del Sitio, incluyendo, sin limitación: acceder a datos no destinados al Usuario o iniciar sesión en un servidor o cuenta en la que el Usuario no esté autorizado a ingresar, intentar interferir con el Servicio a cualquier otro usuario, huésped o red, incluyendo, sin limitación, a través del envío de virus al Sitio, sobrecarga, inundación, spam, enviar correo no solicitado, incluyendo promociones y/o publicidad de productos o servicios, falsificar cualquier encabezado de TCP/IP o cualquier parte de la información del encabezado en cualquier correo electrónico o grupo de noticias, así como la utilización de robots, arañas, orugas, scraper, o cualquier otro medio automatizado para acceder o utilizar los Servicios.-

El Usuario se obliga a no utilizar ningún hardware, software o rutina para interferir o intentar interferir con el uso correcto de cualquiera de las aplicaciones móviles o el Sitio Web o cualquier actividad llevada a cabo en los mismos.-

Cada Usuario será responsable del equipo o dispositivos y servicios de telefonía e internet que pudiera requerir para el perfecto acceso al uso de la Aplicación y el Sitio. El Usuario será el único responsable de eventuales daños que su equipo pudiera sufrir debido al mal uso de cualquier hardware, software o conexiones, así como de la compatibilidad con las aplicaciones móviles o el Sitio Web.-

Asimismo, el Usuario reconoce que la utilización de las aplicaciones móviles o del Sitio Web pueden generar costos adicionales por el uso de datos móviles o internet proporcionados por la compañía de telefonía o internet con la que el Usuario tenga contratados dichos servicios, por lo que ELEX no será responsable de tales costos, ni del servicio que proporcione dicha compañía en favor del Usuario.-

Cualquier violación al sistema o red de seguridad de la Aplicación o del Sitio podrá dar lugar a la cancelación de la cuenta del Usuario, así como al inicio de cualquier procedimiento de responsabilidad civil o penal en contra del Usuario.-

El Usuario reconoce que podrían presentarse (i) interrupciones en la prestación de los Servicios o las Actualizaciones, o, incluso, (ii) otros hechos ocurridos por causas fuera de control de ELEX. ELEX no podrá ser responsabilizado de cualesquiera datos perdidos durante la transmisión de información por medio de Internet.-

ELEX está exento de cualquier responsabilidad que ocurra por interrupciones o suspensiones del servicio de acceso a Internet ocasionadas por la falla en el sistema de telecomunicaciones, en el suministro de energía eléctrica, casos fortuitos o de fuerza mayor o una acción de terceros que puedan inhabilitar los equipos que suministran el acceso a la red.-

Por lo anterior, ELEX no se responsabiliza por cualquier daño, perjuicio o pérdida al Usuario causados por fallas en el sistema, en el servidor o en Internet. ELEX tampoco será responsable por cualquier virus que pudiera infectar el equipo del Usuario como consecuencia del acceso, uso o examen de la Aplicación o del Sitio o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o audio contenidos en el mismo. Los Usuarios no podrán imputarle responsabilidad alguna ni exigir pago de daños o perjuicios, en virtud de dificultades técnicas o fallas en los sistemas o en Internet.-

La Aplicación, el Sitio o cualquier Actualización a los mismos pueden eventualmente no estar disponibles debido a dificultades técnicas o fallas de Internet, o por cualquier otra circunstancia ajena a ELEX; en tales casos se procurará restablecerlo con la mayor celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad. ELEX no será responsable por ningún error u omisión contenidos en la Aplicación o el Sitio.
ELEX podrá, en cualquier momento y a su exclusivo criterio, descontinuar, suspender, terminar o alterar la forma de acceso a la Aplicación o al Sitio con relación a cualquier contenido, periodo de disponibilidad, Actualización y equipamiento necesarios para el acceso y/o utilización de los mismos. ELEX se reserva el derecho de discontinuar la diseminación de cualquier información, alterar o eliminar métodos de transmisión, alterar la velocidad de transmisión de datos o cualesquiera otras características de señal

        </p>

        <h3 className="h3 mb-1 mt-5">Licencia de Uso.</h3>
        <p className="mb-2">
        
Por medio de este documento y sujeto al cumplimiento de los presentes Términos y Condiciones, ELEX otorga en favor del Usuario una licencia personal limitada, no exclusiva, no transferible, no sublicenciable, revocable, por plazo indeterminado, para (i) descargar, instalar y utilizar la Aplicación en su dispositivo móvil, o para acceder a los Servicios por medio del Sitio, y (ii) para utilizar los Servicios; en ambos casos dicha licencia será para uso personal del Usuario y en ningún caso podrá ser sublicenciada, revendida o de cualquier otra forma explotada sin el consentimiento previo y por escrito de ELEX.-

Cualquier uso no autorizado de la Aplicación o del Sitio o en contravención a los presentes Términos y condiciones dará lugar a la terminación inmediata.

        </p>

        <h3 className="h3 mb-1 mt-5">Propiedad intelectual.</h3>
        <p className="mb-2">
        
El software asociado a la Aplicación y al Sitio, así como todos los Contenidos disponibles en los mismos, incluyendo los enlaces, son, para efectos del presente documento, propiedad exclusiva de ELEX, y están protegidas por las leyes y los tratados internacionales en materia de derechos de autor, marcas, patentes, modelos y diseños industriales aplicables en  nuestro pais.-
Queda prohibida la copia, distribución, transmisión, publicación, conexión, ingeniería inversa o cualquier otro tipo de modificación de la Aplicación, el Sitio o de los Contenidos sin expresa y previa autorización de ELEX o, en su caso, del titular de los derechos de propiedad correspondientes.-

Asimismo, se encuentra prohibido al Usuario sublicenciar, vender, revender, transferir, ceder o de cualquier forma explotar o poner a disposición de cualquier tercero la Aplicación o el Sitio, así como crear vínculos en internet hacia o desde la Aplicación o el Sitio, o reproducirlos en sitios “espejo”, sin la autorización previa y por escrito de ELEX.-

Cualquier violación a lo dispuesto en éste apartado constituirá una infracción a los derechos de propiedad intelectual y sujetará al Usuario a las sanciones administrativas, civiles y penales que resulten aplicables.-

ELEX se reserva todos los derechos no expresamente otorgados bajo el presente documento. El Usuario declara y reconoce que la descarga de cualquier contenido de la Aplicación o del Sitio no le confiere la propiedad sobre el mismo.-

Cualesquiera marcas exhibidas en la Aplicación, el Sitio o en cualquier enlace no deben ser consideradas como de dominio público y son propiedad exclusiva de los terceros contratantes de ELEX.-

        </p>

        <h3 className="h3 mb-1 mt-5">Actualizaciones.</h3>
        <p className="mb-2">
        
ELEX podrá, en cualquier momento, realizar modificaciones, adiciones o mejoras a la Aplicación o al Sitio, incluyendo sin limitar, a los Contenidos así como a las funciones y versiones del software de la Aplicación y del Sitio en general (las “Actualizaciones”). Dichas Actualizaciones tienen como objetivo mejorar los Servicios en beneficio del Usuario, así como mostrar Contenidos acordes y actualizados con los intereses de los Usuarios en todo momento, o, en su caso, reparar alguna falla que pudiera presentar la Aplicación o el Sitio.-

ELEX podrá enviarle notificaciones al usuario por medio de la Aplicación, el Sitio Web o correo electrónico, mensaje de texto, o por cualquier otro medio de comunicación proporcionado por el Usuario.-

ELEX se reserva el derecho de establecer alguna contraprestación a cargo del Usuario por la descarga e instalación de cualquier Actualización.-

La descarga e instalación de las Actualizaciones son opcionales para el Usuario, en el entendido que algunas Actualizaciones pueden resultar indispensables para la continuidad de la prestación de los Servicios en su favor, por lo que en caso de que el Usuario se abstenga de descargar e instalar alguna Actualización, la Aplicación o el Sitio podrían dejar de funcionar en su equipo. ELEX no será responsable de cualquier daño o pérdida que pudiere ocasionarse al Usuario como consecuencia de la omisión en la descarga e instalación de cualquier Actualización.-
Los presentes Términos y Condiciones serán aplicables para cualquier Actualización de la Aplicación o del Sitio, salvo estipulación en contrario, por lo que en caso de que el Usuario descargue instale cualquier Actualización se entenderá que acepta los mismos.-

        </p>
      </LayoutSmall>
    ) 
  }
}

export default withTranslation()(InfoTerms);
