import React from 'react';
import Icon from '../../libraries/icons';

export default function CardCategory({iconName, title, ...rest }) {
  return (
    <div className={"text-center px-2 " + (rest.className ? rest.className : "")}>
      <Icon className={"h-10 w-10 text-primary m-auto" + (rest.iconClassName ? rest.iconClassName : "")} name={iconName} />
      <h6 className="h6 text-secondary mb-0 mt-2">{title}</h6>
    </div>
  )
}