export default {
  CLEAR_CURRENT: 'CELLS_CLEAR_CURRENT',
  GET_REQUEST: 'CELLS_GET_REQUEST',
  GET_SUCCESS: 'CELLS_GET_SUCCESS',
  GET_FAILURE: 'CELLS_GET_FAILURE',

  SAVE_REQUEST: 'CELLS_SAVER_REQUEST',
  SAVE_SUCCESS: 'CELLS_SAVE_SUCCESS',
  SAVE_FAILURE: 'CELLS_SAVE_FAILURE',

  GETALL_REQUEST: 'CELLS_GETALL_REQUEST',
  GETALL_SUCCESS: 'CELLS_GETALL_SUCCESS',
  GETALL_FAILURE: 'CELLS_GETALL_FAILURE',

  DELETE_REQUEST: 'CELLS_DELETE_REQUEST',
  DELETE_SUCCESS: 'CELLS_DELETE_SUCCESS',
  DELETE_FAILURE: 'CELLS_DELETE_FAILURE',    

  UPDATE_REQUEST: 'CELLS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CELLS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CELLS_UPDATE_FAILURE'    

};