import React from 'react';
import { history } from '../../routes';
import config from '../../config';
import Icon from '../../libraries/icons';
import UserPoints from './UserPoints';
import UserCoins from './UserCoins';


export default function RowService({ url, title, points, name, distance, ...rest }) {
  return (
    <div className={"overflow-hidden border-b border-gray-100 " + (rest.className ? rest.className : "")} onClick={() => history.push(config.ROUTES.PRODUCT_PAGE)}>
      <div className="flex p-4">
        <div className="w-40 max-h-32 overflow-hidden flex justify-center items-start mr-3">
          <img src={url} />
        </div>
        <div className="w-full">
          <h6 className="h6 text-secondary mb-0">{title}</h6>
          <small className="small text-gray-500">
            de <span className="text-primary">{name}</span>
          </small>
          
          <div className="flex justify-between items-center">
            <div className="flex">
              <UserCoins className="mr-4" icon="coin1" coins="300" leyend=""/>
              <UserPoints icon="impact1" points="1" leyend=""/>
            </div>
            <small className="text-gray-500">{distance} km</small>
          </div>
        </div>
      </div>
    </div>
  )
}