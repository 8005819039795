import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';

import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import CodeInput from '../../components/forms/CodeInput';

class Verify extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      code: null,
    }
  }
  
  componentDidMount() {
    console.log('VERIFY PAGE', this.props);
    const params = this.props.match.params;
    if (params.hash) {
      this.props.onGetFromHash(params).then(()=> {
        console.log('AFTER GET');
        const error = this.props.user.error;
        if (error) {
          history.push(config.ROUTES.LOGIN);
        } else {
          this.setState({ id: this.props.user.item.id});
        }
      });
    } else {
      history.push(config.ROUTES.LOGIN);
    }
  }
  
  onSubmit = async () => {
    console.log('SUBMIT', this.state);
    const data = this.state;
    this.props.onVerifyPIN(data).then(()=> {
      console.log('AFTER VERIFY');
      const error = this.props.auth.error;
      if (error) { 
        notify(this.t(error.message));
      } else {
        history.push(config.ROUTES.LOGIN);
      }
    });
  }

  onSendAgain = async () => {
    console.log('SEND AGAIN');
  }

  onChange = (value) => {
    console.log('CHANGE', value);
    this.setState({ code: value });
  }

  render() {
    return (
      <LayoutSmall
        main={{ className: "bg-primary text-primary-100"}}
        header={{ logo: true }}
      >
        <ToastContainer/>
        <div className="text-center mb-10 text-white">
          <h2 className="mb-4 text-primary-100 h2">{this.t("Verification email")}</h2>
          <p className="text-primary-100">{this.t("Enter code")}</p>
        </div>        
        <form className="w-full max-w-lg">
          <div className="flex">
            <div className="px-3">
              <CodeInput
                name="code"
                type="number"
                fields={6}
                onChange={this.onChange}
                autoFocus={true}
              />
            </div>                
          </div>
          <div className="w-full px-3">
            <Button
              className="btn btn-link ml-auto mb-10 text-primary-100 text-sm"
              title={this.t("Enviar de nuevo")}
              onClick={this.onSendAgain}
            />
            <Button
              className="btn btn-lg btn-block btn-secondary"
              title={this.t("Ingresar")}
              onClick={this.onSubmit}
            />
          </div>
        </form>
      </LayoutSmall>        
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    user: state.users.current,    
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onVerifyPIN: (params) => dispatch(userActions.verifyPIN(params)),
    onGetFromHash: (params) => dispatch(userActions.getFromHash(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Verify));
