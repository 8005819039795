const dotenv = {
  production: {
    BASE_API_URL: 'https://api.sharyco.com/api/v1',
    DEBUG_MODE: false,
    ONESIGNAL_APPID: '',
    ONESIGNAL_SAFARI_WEB_ID: 'web.onesignal.auto.',
    GOOGLE_CLIENT_ID: '.apps.googleusercontent.com',
    FACEBOOK_APP_ID: '',
  },
  development: {
    BASE_API_URL: 'http://192.168.100.136:8891/api/v1',
    DEBUG_MODE: true,
    ONESIGNAL_APPID: '',
    ONESIGNAL_SAFARI_WEB_ID: 'web.onesignal.auto',
    GOOGLE_CLIENT_ID: 'apps.googleusercontent.com',
    FACEBOOK_APP_ID: '',
  }
}

const vars = {
  VERSION: 'v20210129.001',
  CLIENT_ID: '11111111111',
  CLIENT_SECRET: 'qwetqutuqwteutuqwteuqwtuetqwyteuqt',
};

const { NODE_ENV } = process.env;
const dotenvVars = Object.assign(
  ...Object.keys(dotenv[NODE_ENV])
    .map( key => ({ [key]: dotenv[NODE_ENV][key] }) )
);
const general = { ...vars, ...dotenvVars };
export default general;