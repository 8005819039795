import React from 'react';
import { withTranslation } from 'react-i18next';
import config from '../../config';

import Button from '../../components/commons/Button';
import slide0 from '../../assets/img/img-wizard-1.png';
import slide1 from '../../assets/img/img-wizard-2.png';
import slide2 from '../../assets/img/img-wizard-3.png';

const images = Object.freeze({ slide0, slide1, slide2 });
class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      slide: 0,
      slides: null,
      content: []
    }
  }
  
  componentDidMount() {
    console.log('Slider PAGE', config.SLIDER.CONTENT);
    this.setState({ slides: config.SLIDER.CONTENT.length, content: config.SLIDER.CONTENT });
  }
 
  onPressButtonPrev = () => {
    let slide = this.state.slide;
    if (slide > 0) slide--;
    this.setState({ slide });
  };

  onPressButtonNext = () => {
    let slide = this.state.slide;
    if (slide < this.state.slides -1)  {
      slide++;
      this.setState({ slide });
    } else {
      localStorage.setItem(config.SLIDER_SCREEN, JSON.stringify(false));
      this.props.close(false);
    }
  };  

  render() {
    const { slide, content } = this.state
    if (content.length === 0) return null;
    return (
      <main className="container flex flex-col items-center justify-around h-full w-full text-center p-10 bg-white">
        {content.length !== 0 ? (
          <>
            <h1>
              <img src={images[`slide${slide}`]} alt="" width="100%"/>
            </h1>
            <div>
              <h1 className="h1 text-primary">{this.t(content[slide].title)}</h1>
              <p className="text-gray-600">{this.t(content[slide].body)}</p>
            </div>
            <Button
              className="btn btn-lg btn-block btn-primary"
              title={this.t(content[slide].button)}
              onClick={this.onPressButtonNext}
            />
          </>
        ) : (
          <Button
            className="btn btn-lg btn-block btn-primary"
            title={this.t("Start")}
            onClick={() => this.props.close(false)}
          />
        )}
      </main>
    ) 
  }
}

export default withTranslation()(Slider);
